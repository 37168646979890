import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";

// // This is a custom filter UI for selecting
// // a unique option from a list
// function SelectColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });
//     return [...options.values()];
//   }, [id, preFilteredRows]);

//   // Render a multi-select box
//   return (
//     <Form.Select
//       value={filterValue}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </Form.Select>
//   );
// }

const initial = (v) => {
  let words = v.split(" ").filter((word) => word !== "");
  let result = "";

  for (let i = 0; i < words.length && result.length < 2; i++) {
    const char = words[i].charAt(0).toLowerCase();
    if (/[a-z0-9]/.test(char)) {
      result += char;
    }
  }

  return result;
};

const tableColumns = [
  {
    Header: "Company Name",
    accessor: "company_name",
    Cell: (e) => (
      <Link to={"/customers/profile/" + e.row.original.account_number}>
        <object
          data={e.row.original.logo}
          type="image/png"
          className="rounded-circle mb-n2 me-2"
          width="32"
          height="32"
        >
          <img
            src={
              "https://i1.wp.com/cdn.auth0.com/avatars/" +
              initial(e.value) +
              ".png?ssl=1"
            }
            width="32"
            height="32"
            className="rounded-circle my-n1"
            alt="Logo"
          />
        </object>
        <Button variant="outline-primary" className="mt-n2">
          {e.value}
        </Button>
      </Link>
    ),
  },
  {
    Header: "Account Number",
    accessor: "account_number",
    Cell: (e) => (
      <div className="">
        <Link to={"/customers/profile/" + e.value}>
          <Button variant="outline-primary">{e.value}</Button>
        </Link>
      </div>
    ),
  },
  /*{
    Header: "Tenancies",
    accessor: "tenancies",
    Cell: (tableProps) => {
      return tableProps.row.original.tenancies.map((val) => (
        <span>{val}, </span>
      ));
    },
  },*/
];

export { tableColumns };
