import React from "react";

import { Dropdown } from "react-bootstrap";

import { Settings, User } from "react-feather";

//import avatar1 from "../../assets/img/avatars/avatar.jpg";

import useAuth from "../../hooks/useAuth";

import { useNavigate } from "react-router-dom";

const NavbarUser = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  //getUserMetadata();
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={user.avatar}
            className="avatar img-fluid rounded-circle me-1"
            alt="User Avatar"
          />
          <span className="text-dark mx-2">{user.displayName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => navigate("/profile/edit")}>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => {
            window.open("https://www.google.co.uk/", "_blank");
          }}
        >
          Help
        </Dropdown.Item>
        <Dropdown.Item onClick={() => signOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
