import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { PreviewDropzone } from "../../components/Dropzone";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";

const EditBuildingForm = () => {
  const keyContactOptions = [
    { value: "0001", label: "Michael Clark (Sales Director)" },
    { value: "0002", label: "Chris Boultwood (Head of Technology)" },
    { value: "0003", label: "Chris Attwood (Account Director)" },
    { value: "0004", label: "James Smith (Centre Manager)" },
    { value: "0005", label: "Sarah Brown (Portfolio Manager)" },
    { value: "0006", label: "Raghu Ladwa (Account Manager)" },
    { value: "0007", label: "Azi Mukadam (Customer Experience Manager)" },
  ];
  const accountManagerOptions = [
    { value: "0001", label: "Michael Clark (Sales Director)" },
    { value: "0003", label: "Chris Attwood (Account Director)" },
    { value: "0006", label: "Raghu Ladwa (Account Manager)" },
    { value: "0007", label: "Azi Mukadam (Customer Experience Manager)" },
  ];

  const [addressValue, setAddressValue] = useState("");

  useEffect(() => {
    //console.log(addressValue);
    if (addressValue) {
      geocodeByAddress(addressValue.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          console.log("Successfully got latitude and longitude", { lat, lng })
        );
    }
  });

  const [buildingName, setBuildingName] = useState("Bus Works");
  function handleBuildingName(e) {
    setBuildingName(e.target.value);
  }

  const [buildingBio, setBuildingBio] = useState(
    "A super slick space in South West London, The Light Bulb takes its name from Wandsworth’s history of making electrical supplies, including light switches, sockets and plugs. Situated on the appropriately named Filament Walk, today these modern office and studio spaces pay tribute to the area’s past, offering a light and bright place to get stuck into whatever the day brings."
  );
  function handleBuildingBio(e) {
    setBuildingBio(e.target.value);
  }

  const [goLiveDate, setGoLiveDate] = useState("2022-08-26");
  function handleGoLiveDate(e) {
    setGoLiveDate(e.target.value);
  }

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">1 Building name</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Provide a building name - this should be entered exactly as you
              wish it to display everywhere it's used throughout the app.
            </p>
            <FloatingLabel
              controlId="floatingInput"
              label="Building Name"
              className="mb-3"
            >
              <Form.Control
                type="input"
                placeholder={buildingName}
                size="lg"
                onChange={handleBuildingName}
                value={buildingName}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">2 Building bio</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Write or copy/paste the building bio below, this will be used to
              describe the building on the building profile screen.
            </p>
            <FloatingLabel controlId="floatingTextarea2" label="Building Bio">
              <Form.Control
                as="textarea"
                placeholder="Write a short bio for the building here..."
                style={{ height: "100px" }}
                onChange={handleBuildingBio}
                value={buildingBio}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">3 Building address</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Write or copy/paste the building bio below, this will be used to
              describe the building on the building profile screen.
            </p>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyD9I__gvEX0ScDy654-RJ9SOlt2sqeJGb4"
              selectProps={{
                addressValue,
                onChange: setAddressValue,
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: "uk",
                },
              }}
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">4 Go-live date</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              The building go-live date will be displayed on the building
              profile screen and can be used to trigger notifications.
            </p>
            <FloatingLabel controlId="floatingDate" label="Go-live date">
              <Form.Control
                type="date"
                placeholder="Go-Live Date"
                size="lg"
                className="mt-3 w-50"
                value={goLiveDate}
                onChange={handleGoLiveDate}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">5 Upload building image</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Upload the banner image that will be used to represent the
              building on the Building Portfolio and Network Monitoring screens.
            </p>
            <PreviewDropzone className="mt-2" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">6 Select key contacts</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Select one <b>account manager</b> for the building from the list
              below.
            </p>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={accountManagerOptions}
              value={accountManagerOptions[0]}
            />
            <p className="mt-3">
              And select as many <b>key contacts</b> for the building as
              necessary from the list below.
            </p>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={keyContactOptions}
              value={keyContactOptions[1]}
              isMulti
            />
          </Card.Body>
        </Card>
      </Col>
      <Col xl="4">
        <Card className="sticky-top" style={{ top: "1em" }}>
          <Card.Header>
            <Card.Title className="mb-0">Summary</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              The details for you have updated for this building will be
              confirmed below. Check everything is correct before updating the
              building.
            </p>
            {buildingName !== "" ? (
              <div>
                <h4>Building Name</h4>
                <span className="text-lg">{buildingName}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {buildingBio !== "" ? (
              <div>
                <h4>Building Bio</h4>
                <span className="text-lg">{buildingBio}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {addressValue !== "" ? (
              <div>
                <h4>Building Address</h4>
                <span className="text-lg">{addressValue.label}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {goLiveDate !== "" ? (
              <div>
                <h4>Go-Live Date</h4>
                <span className="text-lg">{goLiveDate}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {goLiveDate !== "" &&
            addressValue !== "" &&
            buildingBio !== "" &&
            buildingName !== "" ? (
              <div>
                <Link to="/buildings/portfolio">
                  <Button className="m-2" variant="light">
                    Cancel
                  </Button>
                </Link>
                <Link to="/buildings/profile">
                  <Button className="m-2 w-75 float-end">
                    Update Building
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                <Link to="/buildings/portfolio">
                  <Button className="m-2" variant="light">
                    Cancel
                  </Button>
                </Link>
                <Button className="m-2 w-75 float-end" disabled>
                  Update Building
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const EditBuilding = () => {
  return (
    <IsAllowed to="edit:buildings" fallback={<NotAuthorised />}>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Edit Building</h1>
        <Row className="row-fill">
          <EditBuildingForm />
        </Row>
      </Container>
    </IsAllowed>
  );
};

export default EditBuilding;
