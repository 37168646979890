import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  FloatingLabel,
  Button,
  ProgressBar,
  ListGroup,
} from "react-bootstrap";
import { StyledDropzone, PreviewDropzone } from "../../components/Dropzone";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { Link } from "react-router-dom";
import { useEffect } from "react";
// import IsAllowed from "../../components/IsAllowed";
// import NotAuthorised from "../../components/NotAuthorised";
import { useSelector } from "react-redux";

const AddBuildingForm = () => {
  const [addressValue, setAddressValue] = useState("");

  useEffect(() => {
    //console.log(addressValue);
    if (addressValue) {
      geocodeByAddress(addressValue.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          console.log("Successfully got latitude and longitude", { lat, lng })
        );
    }
  });

  const [percentage, setPercentage] = useState(0);

  const [buildingName, setBuildingName] = useState("");
  function handleBuildingName(e) {
    setBuildingName(e.target.value);
  }

  const [buildingBio, setBuildingBio] = useState("");
  function handleBuildingBio(e) {
    setBuildingBio(e.target.value);
  }

  const [goLiveDate, setGoLiveDate] = useState("");
  function handleGoLiveDate(e) {
    setGoLiveDate(e.target.value);
  }

  useEffect(() => {
    const totalInputs = 4; // Total number of inputs
    let completedInputs = 0;

    if (buildingName) completedInputs++;
    if (buildingBio) completedInputs++;
    if (addressValue) completedInputs++;
    if (goLiveDate) completedInputs++;

    // Calculate the percentage
    const newPercentage = (completedInputs / totalInputs) * 100;
    setPercentage(newPercentage);
  }, [buildingName, buildingBio, addressValue, goLiveDate]);

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">1 Building name</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Provide a building name - this should be entered exactly as you
              wish it to display everywhere it's used throughout the app.
            </p>
            <FloatingLabel
              controlId="floatingInput"
              label="Building Name"
              className="mb-3"
            >
              <Form.Control
                type="input"
                placeholder={buildingName}
                size="lg"
                onChange={handleBuildingName}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">2 Building bio</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Write or copy/paste the building bio below, this will be used to
              describe the building on the building profile screen.
            </p>
            <FloatingLabel controlId="floatingTextarea2" label="Building Bio">
              <Form.Control
                as="textarea"
                placeholder="Write a short bio for the building here..."
                style={{ height: "100px" }}
                onChange={handleBuildingBio}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">3 Building address</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Search for the building's address using the Google search field
              below.
            </p>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyD9I__gvEX0ScDy654-RJ9SOlt2sqeJGb4"
              apiOptions={{ types: ["address"] }}
              selectProps={{
                addressValue,
                onChange: setAddressValue,
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: "uk",
                },
              }}
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">4 Go-live date</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              The building go-live date will be displayed on the building
              profile screen and can be used to trigger notifications.
            </p>
            <FloatingLabel controlId="floatingDate" label="Go-live date">
              <Form.Control
                type="date"
                placeholder="Go-Live Date"
                size="lg"
                className="mt-3 w-50"
                value={goLiveDate}
                onChange={handleGoLiveDate}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">5 Upload building image</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              Upload the banner image that will be used to represent the
              building on the Building Portfolio and Network Monitoring screens.
            </p>
            <PreviewDropzone className="mt-2" />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <Card.Title className="mb-0">6 Upload rooms</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              You can optionally upload a rooms list using the csv template (
              <Link to="#">here</Link>). Drop it on to the upload area below.
            </p>
            <StyledDropzone className="mt-2" />
          </Card.Body>
        </Card>
      </Col>
      <Col xl="4">
        <Card className="sticky-top" style={{ top: "1em" }}>
          <Card.Header>
            <Card.Title className="mb-0">Summary</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              The details for your new building will be confirmed below. Check
              everything is correct before creating the building.
            </p>
            {buildingName !== "" ? (
              <div>
                <h4>Building Name</h4>
                <span className="text-lg">{buildingName}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {buildingBio !== "" ? (
              <div>
                <h4>Building Bio</h4>
                <span className="text-lg">{buildingBio}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {addressValue !== "" ? (
              <div>
                <h4>Building Address</h4>
                <span className="text-lg">{addressValue.label}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            {goLiveDate !== "" ? (
              <div>
                <h4>Go-Live Date</h4>
                <span className="text-lg">{goLiveDate}</span>
                <hr />
              </div>
            ) : (
              ""
            )}
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p className="mb-0 fw-bold">
                  Progress<span className="float-end">{percentage}%</span>
                </p>
                <ProgressBar
                  className="progress-sm gradient"
                  now={percentage}
                />
              </ListGroup.Item>
            </ListGroup>
            {goLiveDate !== "" &&
            addressValue !== "" &&
            buildingBio !== "" &&
            buildingName !== "" ? (
              <div>
                <Link to="/buildings/portfolio">
                  <Button className="m-2" variant="light">
                    Cancel
                  </Button>
                </Link>
                <Link to="/buildings/portfolio">
                  <Button className="m-2 w-75 float-end">
                    Create Building
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                <Link to="/buildings/portfolio">
                  <Button className="m-2" variant="light">
                    Cancel
                  </Button>
                </Link>
                <Button className="m-2 w-75 float-end" disabled>
                  Create Building
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const NewBuilding = () => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  return (
    // <IsAllowed to="create:buildings" fallback={<NotAuthorised />}>
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        New building{" "}
        <span className="text-sm text-muted">
          ({selectedLandlord.landlordname})
        </span>
      </h1>
      <Row className="row-fill">
        <AddBuildingForm />
      </Row>
    </Container>
    // </IsAllowed>
  );
};

export default NewBuilding;
