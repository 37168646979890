import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchBuildingsRequest,
  fetchBuildingsSuccess,
  fetchBuildingsFailure,
} from "../slices/buildingsSlice";
import axios from "axios";
import { apiConfig } from "../../config";

function fetchBuildingsApi(bearerToken, selectedLandlord, query) {
  const axiosConfig = {
    headers: { Authorization: bearerToken },
  };
  if (query) {
    axiosConfig.params = {
      page_size: 1000,
      order_by: "building_name ASC",
      building_operator: selectedLandlord.landlordid,
    };
  } else {
    axiosConfig.params = {
      page_size: 1000,
      order_by: "building_name ASC",
    };
  }
  const apiUrl = `${apiConfig.connectedApiUrl}buildings`;
  return axios.get(apiUrl, axiosConfig);
}

function* fetchBuildingsSaga(action) {
  try {
    const { bearerToken, selectedLandlord, query } = action.payload;
    const response = yield call(
      fetchBuildingsApi,
      bearerToken,
      selectedLandlord,
      query
    );
    yield put(fetchBuildingsSuccess(response.data.buildings.items));
  } catch (err) {
    yield put(fetchBuildingsFailure(err.message));
  }
}

export function* watchFetchBuildings() {
  yield takeLatest(fetchBuildingsRequest.type, fetchBuildingsSaga);
}
