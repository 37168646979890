import React, { useEffect, useState } from "react";

import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Navbar,
  Row,
} from "react-bootstrap";

import { Mail, Sliders, Smartphone, Users } from "react-feather";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../constants";

import useTheme from "../../hooks/useTheme";
import useSidebar from "../../hooks/useSidebar";
import useLayout from "../../hooks/useLayout";

import { ReactComponent as Logo } from "../../assets/img/static-8-emblem.svg";

import screenshotMSPConsole from "../../assets/img/screenshots/layer8-msp-console3.png";

const Navigation = () => (
  <Navbar expand="md" className="landing-navbar">
    <Container>
      <Navbar.Brand className="landing-brand" href="/">
        <Logo /> Layer8 MSP Console{" "}
      </Navbar.Brand>
      <Button
        href="/dashboard/default"
        target="_top"
        rel="noopener noreferrer"
        variant="primary"
        className="ms-2"
        size="lg"
      >
        Sign-In Now
      </Button>
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
    <Container className="landing-intro-content">
      <Row className="align-items-center">
        <Col lg="5" className="mx-auto">
          <h1 className="my-4">
            The Layer8 MSP Console is a purpose-built UI for MSPs to utilise the
            Layer8 Platform.
          </h1>

          <p className="text-lg">
            The MSP Console application is a user-centric interface that allows
            Managed Service Providers to manage a portfolio of buildings and the
            underlying technology services within them.
          </p>

          <div className="my-4">
            <a
              href="/dashboard/default"
              className="btn btn-primary btn-lg me-2"
            >
              Sign-In Now
            </a>
          </div>
        </Col>
        <Col lg="7" className="d-none d-lg-flex mx-auto text-center">
          <div className="landing-intro-screenshot pb-3">
            <img
              src={screenshotMSPConsole}
              alt="Dark/Light Bootstrap React Admin Template"
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

const Features = () => (
  <section className="py-6">
    <Container>
      <Row>
        <Col md="10" className="mx-auto text-center">
          <div className="mb-5">
            <h2 className="h1">Features you'll love</h2>
            <p className="text-muted text-lg">
              The Layer8 MSP Console was created with your users in mind.
            </p>
          </div>

          <Row className="text-start">
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Sliders />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Service Management</h4>
                  <p className="fs-lg">
                    Configure your clients' services such as network bandwidth,
                    Wi-Fi credentials and building access.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Smartphone />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Fully Responsive</h4>
                  <p className="fs-lg">
                    With mobile, tablet & desktop support it doesn't matter what
                    device you're using. MSP Console is responsive in all
                    browsers.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Mail />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Support Tickets</h4>
                  <p className="fs-lg">
                    View, manage and update your support tickets from all
                    sources in one easy to use interface.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Users />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Secure Role-Based Access Control</h4>
                  <p className="fs-lg">
                    The Layer8 Platform allows you to give the right level of
                    access to those people in your organisation that need it.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

const Faq = () => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <section className="bg-white py-6">
      <Container>
        <div className="mb-5 text-center">
          <h2 className="h1">Frequently Asked Questions</h2>
          <p className="text-muted fs-lg">
            Here are some of the answers you might be looking for.
          </p>
        </div>

        <Row>
          <Col md={9} lg={8} className="mx-auto">
            <Accordion activeKey={activeKey}>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("0")}
                >
                  <h6 className="mb-0">Who is the MSP Console for?</h6>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="py-3">
                    The Layer8 MSP Console is for Managed Service Providers and
                    other organisations that manage multiple services, often
                    accross multiple building portfolios and for multiple client
                    landlords. Our user-centric application gives the right
                    tools to the right users within your organisation.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("2")}
                >
                  <h6 className="mb-0">
                    How often do you update Layer8 Platform features?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="py-3">
                    {/*eslint-disable */}
                    We're always listening to feedback from our customers about
                    what they would like to see in the Layer8 Platform. If you
                    are missing a feature then please let us know by submitting
                    a feedback response{" "}
                    <a href="#" rel="noopener noreferrer" target="_top">
                      here
                    </a>
                    .{/*eslint-enable */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const Footer = () => (
  <section className="landing-footer py-6">
    <Container className="text-center landing-footer-container">
      <Row>
        <Col md="9" lg="8" xl="6" className="mx-auto">
          <h2 className="h1 mb-3">
            Interested in the Layer8 Platform and the MSP Console?
          </h2>

          <Button
            variant="dark"
            size="lg"
            href="https://layereight.io/contact#book-demo"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-n1"
          >
            Book a Demo
          </Button>
        </Col>
      </Row>
    </Container>
  </section>
);

const Landing = () => {
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();

  useEffect(() => {
    setTheme(THEME.DEFAULT);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Intro />
      <Features />
      <Faq />
      <Footer />
    </React.Fragment>
  );
};

export default Landing;
