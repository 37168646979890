import React, { useState } from "react";

import { Offcanvas } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const OffcanvasBodyText = (props) => {
  if (props.id === "wi-fi") {
    return (
      <>
        The Wi-fi credentials section is where users of the Wi-Fi networks and
        their device limits are managed. You can create and create Pre-shared
        keys without having to raise a support ticket. You can search for
        usernames, customer accounts numbers or use the Search and Filter
        options to find specific users. There are two different credential
        types. For Dot11 users you'll see 802.1x and DPSK for Dynamic Pre Shared
        Keys typically used for Smart devices or Printers. For DPSK Credentials,
        you are unable to view or edit the key once it's been created.
        <br />
        <br />
        <h5>Password Reset, Delete, Edit Access Levels</h5>
        Use the key icon to reset passwords or the trash can icon to delete
        credentials. With Dot 11 users, the cog icon allow you to remove all
        user’s devices. You can also enable or disable LAN access. When enabled
        the user can access the customer's VLAN, otherwise they canonly access
        the guest VLAN.
        <br />
        <br />
        <h5>Add New Credential - Dot 11</h5>
        If you need to create a new Wi-Fi credential, go to the … and choose
        from Add New 802.1X credential to create a standard user. Then Select
        the company from the dropdown. You can also type the company and it will
        auto filter. Add the User's first name and last name. Add the user's
        email address and also use this for the username. If you're setting up a
        second credential for an existing user, you can use a different username
        rather than the email address. Select their access level between Web and
        LAN or just web only. Then select create Credential to finishing adding
        a new dot 11 user.
        <br />
        <br />
        <h5>Add New Credential - Ruckus</h5>
        If you want to add a new DPSK credential, choose the option from the ...
        icon. Select the Wireless network from the DSPK mode enabled list, the
        associated company and an email address which will also be the DPSK
        username. You can use the generate button to create a strong password
        that meets the requirements. Ensure you take a copy of the password as
        you will not be able to view it after completing the form. We've added a
        copy to clipboard button to help. Finally, select the Create DPSK to
        finish and your new credential has been added.
        <br />
        <br />
      </>
    );
  } else if (props.id === "networksoverview") {
    return (
      <>
        The Network overview which shows building availability status. Any
        buildings which are not meeting their target threshold will be
        highlighted. When you hover over each building, you can see more detail
        on the stats.
        <br />
        <br />
      </>
    );
  } else if (props.id === "ticketsoverview") {
    return (
      <>
        The current incident tickets section shows open tickets. Select the
        Reference number and you open the ticket details, quickly getting you to
        information about any of the highlighted buildings.
        <br />
        <br />
      </>
    );
  } else if (props.id === "customersoverview") {
    return (
      <>
        In the Customer & Sales Overview, we see key stats around number new
        Customers, total Revenue and the latest NPS score. The product sales
        graph breaks down revenue by the top categories. <br />
        <br />
      </>
    );
  } else if (props.id === "youraccount") {
    return (
      <>
        <br />
        <br />
      </>
    );
  } else if (props.id === "billing") {
    return (
      <>
        In the Billing section you can see key stats and dates around revenue,
        invoices and customers. Invoices can be searched and filtered with the
        invoices able to be downloaded as a PDF.
        <br />
        <br />
      </>
    );
  } else if (props.id === "buildingportfolio") {
    return (
      <>
        In the Building Portfolio you all see a list of all the buildings with
        status markers to show if the building is live. Along with a building
        photo, you get information on the number of room and current customers
        which also show you a quick occupancy rate. You'll also see pictures of
        the key contacts.
        <br />
        <br />
      </>
    );
  } else if (props.id === "buildingprofile") {
    return (
      <>
        The Building Profile provides more detail about each asset. You can
        quickly see the Building status, Bio and it's creation and go-live date
        along with the Key Contacts. Our Account manager and their contact
        details is also handily here.
        <br />
        <br />
        <h5>Rooms</h5>The Rooms list details the building unit numbers, the
        floor and square foot size of the space. It also shows the Current
        tenants. And selecting the name of the occupier takes you through to
        their Customer Profile.
        <br />
        <br />
      </>
    );
  } else if (props.id === "customers") {
    return (
      <>
        {" "}
        This shows key stats around all the customers as well as a table list.
        You can easily see the growth of new customers and rentention rates
        along with the latest NPS. You can find specific information about
        companies or buildings in two ways. Firstly, you can use the filters
        above the table or you can use the Search & Filter box
        <br />
        <br />
      </>
    );
  } else if (props.id === "customerprofile") {
    return (
      <>
        {" "}
        In the Customer profile we include primary contact details and account
        number, the logo, domain and description also will be populated if
        available. <br />
        <br />
      </>
    );
  } else if (props.id === "networkmonitoring") {
    return (
      <>
        The Network Monitoring section gives us a view of all the buildings and
        the status of the network at each of them. You can quickly see a view of
        the network availability chart over the last 10 days. The bandwidth
        allocation gives you the split between the D I A (dedicated Internet
        access) and the open secure internet access for each building.
        <br />
        <br />
      </>
    );
  } else if (props.id === "tenantnetworks") {
    return (
      <>
        This gives you a more detailed of network details and stats including
        headline stats of the last 30 days of latency, jitter and packet loss.
        Then we've more information about the network for the building,
        including the technical reference, bandwidth allocations along with the
        circuit references.
        <br />
        <br />
        <h5>Usage graph</h5>We've then got a network availablility and bandwidth
        usage tracking graph. You can select and zoom into a date range to delve
        deeper.
        <br />
        <br />
        <h5>Device list</h5>This lists the building's devices including core
        router, core switch and access switches. You can select between them to
        see individual device statistics.
        <br />
        <br />
        <h5>Device info</h5>For each device we have the hostname, device type
        info and management IP address. The latest availability, CPU usage and
        Memory usage stats.
        <br />
        <br />
      </>
    );
  } else if (props.id === "userprofile") {
    return (
      <>
        This shows how your Public Profile looks for other users of the platform
        and a timeline of your activities.
        <br />
        <br />
      </>
    );
  } else if (props.id === "edityourprofile") {
    return (
      <>
        You can update your profile info here. You can also add your photo with
        an integration with Gravatar.com via the button
        <br />
        <br />
      </>
    );
  } else if (props.id === "systemsettings") {
    return (
      <>
        This is where you can find the User Management options. You'll see a
        list of the current users including their roles. You can search for
        individuals using the filters at the top.
        <br />
        <br />
        <h5>Add New User</h5>If you need to add a new user just select the Add
        user button. Enter their name and email address which will be their
        sign-in username. If they are a Customer user, which company they are
        with and their role in the Layer8 Platform. Then confirm using the
        Create User button.
        <br />
        <br />
        <h5>Role types</h5>The roles that can be assign depends on the
        permission of the user. Landlord admins can add their users as well as
        tenant admin & users. Customer Tenant admins can only add their own
        users.
        <br />
        <br />
      </>
    );
  } else if (props.id === "supporttickets") {
    return (
      <>
        An overview of all the tickets open related to customers. You can search
        and filter by companies, buildings, status or reference numbers. There's
        also the key ticket stats. Selecting a ticket reference number takes you
        through to the full details, and conversation of that ticket. <br />
        <br />
      </>
    );
  } else if (props.id === "viewingticket") {
    return (
      <>
        This shows the conversation thread of the ticket. You can reply to and
        update the ticket at the bottom.
        <br />
        <br />
      </>
    );
  } else {
    return (
      <>
        <h5>Need some help?</h5>
        If you need some help with this page please contact support using the
        details in the "Need Some Help?" box.
      </>
    );
  }
};

const OffcanvasHelp = ({ name, id, ...props }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className="mx-2 text-muted text-lg"
        onClick={toggleShow}
      />
      <Offcanvas show={show} placement="end" onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <OffcanvasBodyText test="test" id={id} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffcanvasHelp;
