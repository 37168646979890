import * as Yup from "yup";

const simulateTask = (taskName) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const isSuccess = Math.random() > 0.1;
      if (isSuccess) {
        resolve(`${taskName} succeeded`); // Indicate which task succeeded
      } else {
        reject(new Error(`${taskName} failed`)); // Indicate which task failed
      }
    }, Math.random() * 9000 + 1000); // Random delay between 1s and 3s
  });
};

const formConfig = {
  name: "customerMoveIn",
  title: "CREATE NEW TENANCY",
  steps: [
    {
      title: "Customer Details",
      description: "Select which customer to move-in",
      fields: [
        {
          name: "customer",
          label: "Select Customer",
          confirmLabel: "Customer",
          type: "select",
          options: [
            {
              label: "Wayne Enterpises",
              value: "CRM00001",
              name: "customer",
            },
            {
              label: "Oscorp",
              value: "CRM00002",
              name: "customer",
            },
            {
              label: "Daily Planet",
              value: "CRM00003",
              name: "customer",
            },
            {
              label: "LexCorp",
              value: "CRM00004",
              name: "customer",
            },
            {
              label: "Gringotts Wizarding Bank",
              value: "CRM00005",
              name: "customer",
            },
            {
              label: "Xavier's School for Gifted Youngsters",
              value: "CRM00006",
              name: "customer",
            },
            {
              label: "Axme Corporation",
              value: "CRM00007",
              name: "customer",
            },
            {
              label: "Hogwarts School of Witchcraft and Wizardry",
              value: "CRM00008",
              name: "customer",
            },
            {
              label: "Wakanda Design Group",
              value: "CRM00009",
              name: "customer",
            },
            {
              label: "Stark Industries",
              value: "CRM00010",
              name: "customer",
            },
          ],
          validation: Yup.string().required("Customer is required"),
        },
      ],
      validationSchema: Yup.object().shape({
        customer: Yup.object()
          .shape({
            value: Yup.string().required("Customer selection is required"),
          })
          .required("Customer is required"),
      }),
    },
    {
      title: "Building & Unit",
      description: "Choose a building and room",
      fields: [
        {
          name: "building",
          label: "Select Building",
          confirmLabel: "Building",
          type: "select",
          options: [
            {
              label: "Wayne Manor",
              value: "muWM",
              name: "building",
            },
          ],
          validation: Yup.string().required("Building is required"),
        },
        {
          name: "unit",
          label: "Select Unit",
          confirmLabel: "Unit",
          type: "select",
          options: [
            {
              label: "Unit 101",
              value: "UNIT101",
              name: "unit",
            },
            {
              label: "Unit 102",
              value: "UNIT102",
              name: "unit",
            },
            {
              label: "Unit 103",
              value: "UNIT103",
              name: "unit",
            },
            {
              label: "Unit 104",
              value: "UNIT104",
              name: "unit",
            },
            {
              label: "Unit 105",
              value: "UNIT105",
              name: "unit",
            },
          ],
          validation: Yup.string().required("Unit is required"),
        },
      ],
      validationSchema: Yup.object().shape({
        building: Yup.object().shape({
          value: Yup.string().required("Building selection is required"),
        }),
        unit: Yup.object().shape({
          value: Yup.string().required("Unit selection is required"),
        }),
      }),
    },
    {
      title: "Move-In Date",
      description: "Choose the move-in date",
      fields: [
        {
          name: "moveInDate",
          label: "Move-In Date",
          confirmLabel: "Move-In Date",
          type: "date",
          validation: null,
        },
      ],
      validationSchema: Yup.object().shape({
        moveInDate: Yup.date().required("Move-in date is required"),
      }),
    },
    {
      title: "Service Provisioning",
      description: "Specify service provisioning options",
      fields: [
        {
          name: "provisionOSIA",
          title: "Wired Network Setup",
          label: "Provision the wired network",
          confirmLabel: "Provision wired network",
          infoBox:
            "Select this option if you want to automatically provision the customer IT network for the unit.",
          type: "switch",
          validation: null,
        },
        {
          name: "bandwidthSelection",
          title: "Configure bandwidth allowance",
          label: "Choose bandwidth allowance for customer",
          confirmLabel: "Bandwidth allowance",
          type: "range",
          validation: null,
          condition: (formValues) => formValues.provisionOSIA === true,
        },
        {
          name: "vlanSelection",
          title: "Customer VLAN selection",
          label: "Customer VLAN selection",
          confirmLabel: "VLAN for customer network",
          type: "select",
          options: [
            {
              label: "Automatic",
              value: "AUTO",
              name: "vlanSelection",
            },
            {
              label: "VLAN 102",
              value: "VLAN102",
              name: "vlanSelection",
            },
            {
              label: "VLAN 103",
              value: "VLAN103",
              name: "vlanSelection",
            },
            {
              label: "VLAN 104",
              value: "VLAN104",
              name: "vlanSelection",
            },
            {
              label: "VLAN 105",
              value: "VLAN105",
              name: "vlanSelection",
            },
          ],
          validation: null,
          condition: (formValues) => formValues.provisionOSIA === true,
        },
        {
          name: "provisionWifiUsers",
          title: "Wireless Network Setup",
          label: "Provision user wi-fi credentials",
          confirmLabel: "Provision wireless network",
          infoBox:
            "Select this option if you want to generate wireless network credentials for the customer.",
          type: "switch",
          validation: null,
        },
        {
          name: "wifiAuthenticationMode",
          title: "Authentication mode for wi-fi users",
          label: "Choose authentication mode for wi-fi users",
          confirmLabel: "Authentication mode for wi-fi users",
          type: "select",
          options: [
            {
              label: "Dynamic Pre-Shared Key",
              value: "DPSK",
              name: "wifiAuthenticationMode",
            },
            {
              label: "802.1X",
              value: "DOT1X",
              name: "wifiAuthenticationMode",
            },
          ],
          validation: null,
          condition: (formValues) => formValues.provisionWifiUsers === true,
        },
        {
          name: "sendWifiCredentials",
          title: "Credential delivery",
          label: "Send wi-fi onboarding email to customer?",
          confirmLabel: "Send credentials to customer",
          type: "checkbox",
          validation: null,
          condition: (formValues) => formValues.provisionWifiUsers === true,
        },
      ],
      validationSchema: null,
    },
    {
      title: "Confirm Details",
      confirmationStep: true,
      description: "Review details and create tenancy",
      fields: [
        {
          name: "confirm",
          label: "Confirm that the below details are correct",
          type: "confirm",
          validation: null,
        },
      ],
      validationSchema: null,
    },
    {
      title: "Work Some Magic",
      description: "Run the automated tasks",
      confirmationStep: true,
      fields: [],
    },
  ],
  handleSubmit: async (
    formData,
    { setSubmissionStatus, token, updateTaskStatus }
  ) => {
    console.log("Attempting to submit form data:", formData);

    // Initialize an empty array for tasks to start with a clean state
    setSubmissionStatus({ isLoading: true, tasks: [] });

    const tasks = [
      "Create tenancy record",
      "Associate VLAN with customer",
      "Queue wired network provisioning job",
      "Queue wi-fi credential provisioning job",
    ]; // Define your tasks
    tasks.forEach((taskName) => {
      // Update the status to loading for each task initially
      updateTaskStatus(taskName, "loading", null);

      // Simulate each task and update the status accordingly
      simulateTask(taskName)
        .then((result) => {
          updateTaskStatus(taskName, "succeeded", result);
        })
        .catch((error) => {
          updateTaskStatus(taskName, "failed", error.message);
        });
    });
  },
};

export default formConfig;
