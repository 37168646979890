import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { Button, Container, Row, Breadcrumb } from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import BuildingsList from "./components/BuildingsList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Buildings = () => {
  return (
    <IsAllowed to="view:buildings" fallback={<NotAuthorised />}>
      <Helmet title="Building Portfolio" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-2">
          <Breadcrumb.Item href="/dashboard/default">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Building Portfolio</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Building Portfolio
          <OffcanvasHelp
            id="buildingportfolio"
            name="Building Portfolio"
            scroll
            backdrop
          />
        </h1>
        <IsAllowed to="create:buildings">
          <Link to="/buildings/new">
            <Button variant="primary" className="mt-n1 mb-3">
              <FontAwesomeIcon icon={faPlus} /> New Building
            </Button>
          </Link>
        </IsAllowed>
        <div className="clearfix"></div>
        <Row className="d-flex">
          <BuildingsList />
        </Row>
      </Container>
    </IsAllowed>
  );
};

export default Buildings;
