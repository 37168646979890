import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  buildingsData: [],
  noData: false,
  error: "",
};

const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    fetchBuildingsRequest(state) {
      state.loading = true;
    },
    fetchBuildingsSuccess(state, action) {
      state.loading = false;
      state.buildingsData = action.payload;
      state.noData = false;
      state.error = "";
    },
    fetchBuildingsFailure(state, action) {
      state.loading = false;
      state.buildingsData = [];
      state.noData = true;
      state.error = action.payload;
    },
  },
});

export const {
  fetchBuildingsRequest,
  fetchBuildingsSuccess,
  fetchBuildingsFailure,
} = buildingsSlice.actions;
export default buildingsSlice.reducer;
