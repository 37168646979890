import { Badge, Card, ListGroup, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PLACEHOLDER_IMAGE = "/assets/img/buildings/placeholder.jpeg";

const BuildingListItem = ({
  name,
  status,
  color = "secondary",
  percentage,
  numrooms,
  numcustomers,
  technicalreference,
  buildingid,
}) => {
  const getBuildingImagePath = () => {
    const path = `/assets/img/buildings/${technicalreference}.webp`;
    const img = new Image();
    img.src = path;
    img.onerror = () => (img.src = PLACEHOLDER_IMAGE);
    return img.src;
  };

  const handleError = (event) => {
    event.target.onerror = null;
    event.target.src = PLACEHOLDER_IMAGE;
  };

  return (
    <Card>
      <Link to={`/buildings/profile/${buildingid}`}>
        <Card.Img
          src={getBuildingImagePath()}
          alt="Building Image"
          className="card-img-aspect"
          onError={handleError}
        />
        <div className="card-img-overlay">
          <Badge
            className={
              status === "Live Building" ? "my-2 btn-gradient" : "my-2"
            }
            bg={color}
          >
            {status}
          </Badge>
        </div>
        <Card.Header className="px-4 pt-4">
          <Card.Title className="mb-0">{name}</Card.Title>
        </Card.Header>
      </Link>
      <Card.Body className="px-4 pt-1 pb-1">
        <p className="mb-0">
          <span className="pe-2 text-nowrap">
            <FontAwesomeIcon icon={faBuilding} />
            &nbsp;<b>{numrooms}</b> Rooms
          </span>
          <span className="text-nowrap">
            <FontAwesomeIcon icon={faUserCircle} />
            &nbsp;<b>{numcustomers}</b> Customers
          </span>
        </p>
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item className="px-4 pb-4">
          <p className="mb-0 fw-bold">
            Occupancy<span className="float-end">{percentage}%</span>
          </p>
          <ProgressBar className="progress-sm gradient" now={percentage} />
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default BuildingListItem;
