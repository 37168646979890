import { createSlice } from "@reduxjs/toolkit";
import demologo from "../../assets/img/logos/bigbuildingsinc-logo.png";

const initialState = {
  landlordid: 42,
  landlordname: "Wavenet Connected Internal",
  landlordlogo: demologo,
};

export const landlordSlice = createSlice({
  name: "landlord",
  initialState,
  reducers: {
    incrementLandlord: (state) => {
      state.landlord += 1;
      return state;
    },
    setLandlord: (state, action) => {
      state.landlordid = action.payload.id;
      state.landlordname = action.payload.name;
      state.landlordlogo = action.payload.logo;
      return state;
    },
  },
});

export const { incrementLandlord, setLandlord } = landlordSlice.actions;

export default landlordSlice.reducer;
