import React from "react";

import { Card, Row, Col, Button } from "react-bootstrap";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";

import IsAllowed from "./IsAllowed";

const SupportOptions = {
  billing: {
    type: "billing",
    phone: "0330 043 4304",
    email: "billing@layereight.io",
    description: "Contact the Customer Experience team using the details below",
  },
  commercial: {
    type: "commercial",
    phone: "0330 043 4304",
    email: "commercial@layereight.io",
    description: "Contact the Account Management team using the details below",
  },
  technical: {
    type: "technical",
    phone: "0330 043 4304",
    email: "support@layereight.io",
    description: "Contact the Tech Support team using the details below",
  },
  support: {
    type: "support",
    phone: "0330 043 4304",
    email: "support@layereight.io",
    description: "Contact the Support team using the details below",
  },
  wifi: {
    type: "wifi",
    phone: "0330 043 4304",
    email: "wifi@layereight.io",
    description: "Contact the Wi-Fi Support team using the details below",
  },
};

const GetSupport = (props) => {
  const helptype = SupportOptions[props.type];
  return (
    <Card className="illustration-support flex-fill">
      <Card.Body className="p-0 d-flex flex-fill">
        <Row className="row g-0 w-100">
          <Col xs="12">
            <div className="illustration-support-text p-3 m-1">
              <h4 className="illustration-support-text">Need some help?</h4>
              <p className="mb-0">{helptype.description}</p>
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body style={{ background: "#FFF", color: "#6c757d" }}>
        <FontAwesomeIcon icon={faPhone} className="mx-3" />
        <span className="text-lg">
          <a href={"tel:" + helptype.email}>{helptype.phone}</a>
        </span>
        <hr />
        <FontAwesomeIcon icon={faEnvelope} className="mx-3" />
        <span className="text-lg">
          <a href={"mailto:" + helptype.email}>{helptype.email}</a>
        </span>
        <IsAllowed to="create:tickets">
          <hr />
          <FontAwesomeIcon icon={faTicket} className="mx-3" />
          <span className="text-lg">
            <Link to="/tickets/new">
              <Button className="btn" variant="primary">
                Open a support ticket
              </Button>
            </Link>
          </span>
        </IsAllowed>
      </Card.Body>
      <Card.Footer className="p-1"></Card.Footer>
    </Card>
  );
};

export default GetSupport;
