import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import GetSupport from "../../components/GetSupport";
// import CustomerStats from "./CustomerStats";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
//import { ReactComponent as LogoLoader } from "../../assets/img/layer8-animated-loader.svg";
// import LogoLoader from "../../components/LogoLoader.js";
import LongDataLoader from "../../components/LongDataLoader.js";

import { Card, Col, Container, Row, Breadcrumb } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";
import { tableColumns } from "./customerData.js";
import { setCustomers } from "../../redux/slices/customers";

const CustomersList = () => {
  const { userAccessToken } = useAuth();
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const selectedCustomer = useSelector((state) => state.searchfilters);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [customersData, setCustomersData] = useState([]);

  const bearerToken = `Bearer ${userAccessToken}`;

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(`${apiConfig.coreApiUrl}organisations?type=customer`, axiosConfig)
      .then((response) => {
        const customersList = response.data.map((customer) => ({
          account_number: customer.external_id,
          company_name: customer.organisation_name,
          logo: `https://logo.clearbit.com/${customer.domain}`,
          tenancies: [],
        }));
        setLoading(false);
        setCustomersData(customersList);
        dispatch(setCustomers(customersList));
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [bearerToken, selectedLandlord, dispatch, selectedCustomer]);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Customer List</Card.Title>
      </Card.Header>
      <Card.Body className="pt-0">
        {loading ? (
          // <LogoLoader className="d-block m-auto p-4" />
          <LongDataLoader />
        ) : noData ? (
          <NotFound />
        ) : (
          <ColumnFilteringTable columns={tableColumns} data={customersData} />
        )}
      </Card.Body>
    </Card>
  );
};

const Customers = () => (
  <IsAllowed to="view:customers" fallback={<NotAuthorised />}>
    <React.Fragment>
      <Helmet title="Customers" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-0">
          <Breadcrumb.Item href="/dashboard/default">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Customers</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Customers
          <OffcanvasHelp id="customers" name="Customers" scroll backdrop />
        </h1>
        <div className="clearfix"></div>
        {/* <Row>
          <CustomerStats />
        </Row> */}
        <Row>
          <Col xl="9">
            <CustomersList />
          </Col>
          <Col xl="3">
            <GetSupport type="commercial" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  </IsAllowed>
);

export default Customers;
