import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Tab,
} from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
//import Select from "react-select";
//import { PhoneInput } from "react-contact-number-input";

import useAuth from "../../hooks/useAuth";

import NotyfContext from "../../contexts/NotyfContext";
import IsAllowed from "../../components/IsAllowed";
import axios from "axios";
import { apiConfig } from "../../config";
import NotificationsTable from "./Notifications";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Profile Settings</Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item tag="a" href="#1" action>
        Profile Info
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#2" action>
        Login Details
      </ListGroup.Item>
      <IsAllowed to="edit:notifications:own">
        <ListGroup.Item tag="a" href="#3" action>
          Notifications
        </ListGroup.Item>
      </IsAllowed>
    </ListGroup>
  </Card>
);

const ProfileInfo = () => {
  const { user, userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  if (
    user &&
    user.roles &&
    user.roles.includes("superuser") &&
    process.env.NODE_ENV === "development"
  ) {
    console.log("User:" + JSON.stringify(user));
    console.log("UAT:" + userAccessToken);
    console.log("Environment:" + JSON.stringify(process.env));
  }

  const notyf = useContext(NotyfContext);

  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.usersApiUrl + "users/" + user.email + "/pwreset",
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          notyf.open({
            type,
            message: "A password reset email has been sent to you.",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notyf.open({
          type: "warning",
          message: err.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
  };

  return (
    <Card>
      <Tab.Content>
        <Tab.Pane eventKey="#1">
          <Card.Header>
            <Card.Title className="mb-0">Profile Info</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Form>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputUsername">
                      Username{" "}
                      <span className="text-muted">(email address)</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="inputUsername"
                      placeholder={user.email}
                      disabled
                    />
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="text"
                          id="name"
                          placeholder="Name"
                          defaultValue={user.displayName}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="6" className="justify-content-center">
                  <div className="text-center">
                    <img
                      alt={user.displayName}
                      src={user.avatar}
                      className="rounded-circle img-responsive mt-2"
                      width="128"
                      height="128"
                    />
                    <div className="mt-2">
                      <a
                        href="https://gravatar.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faExternalLinkAlt} /> Manage on
                          Gravatar.com
                        </Button>
                      </a>
                    </div>
                    <small>
                      We use Gravatar.com, the global avatar service.
                    </small>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Tab.Pane>
        <Tab.Pane eventKey="#2">
          <Card.Header>
            <Card.Title className="mb-0">Login Details</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Form>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="inputUsername2">
                      Username{" "}
                      <span className="text-muted">(email address)</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="inputUsername2"
                      placeholder={user.email}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" onClick={(e) => handlePasswordReset(e)}>
                Send password reset email
              </Button>
            </Form>
          </Card.Body>
        </Tab.Pane>
        <Tab.Pane eventKey="#3">
          <Card.Header>
            <Card.Title className="mb-0">Notifications</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <p>
              You can choose to receive notifications from Layer8 Platform when
              certain events occur. Notifications will be displayed in the app,
              and optionally can be sent to you via email or SMS.
            </p>
            <NotificationsTable />
          </Card.Body>
        </Tab.Pane>
      </Tab.Content>
    </Card>
  );
};

const EditProfile = () => (
  <React.Fragment>
    <Helmet title="Your Profile" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        Your Profile
        <OffcanvasHelp
          id="edityourprofile"
          name="Edit Your Profile"
          scroll
          backdrop
        />
      </h1>
      <Tab.Container id="edit-profile-tabs" defaultActiveKey="#1">
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            <ProfileInfo />
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  </React.Fragment>
);

export default EditProfile;
