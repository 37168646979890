import React, { useState } from "react";

import { Badge, Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import BandwidthAmount from "./BandwidthAmount";

const BuildingNetworkInfo = (data) => {
  const [showPrimary, setShowPrimary] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showTertiary, setShowTertiary] = useState(false);

  const togglePrimary = () => setShowPrimary(!showPrimary);
  const toggleSecondary = () => setShowSecondary(!showSecondary);
  const toggleTertiary = () => setShowTertiary(!showTertiary);

  return (
    <Card className="flex-fill">
      <Card.Header>
        <Card.Title className="mb-0">Building Network Details</Card.Title>
      </Card.Header>
      <Card.Body className="py-0">
        <Table size="">
          <tbody>
            <tr>
              <th>Building Name</th>
              <td>{data.building_name}</td>
            </tr>
            <tr>
              <th>Technical Reference</th>
              <td>{data.data.building_code}</td>
            </tr>
            <tr>
              <th>Total Bandwidth Available</th>
              <td>
                <Badge bg="danger" className="btn-gradient">
                  <BandwidthAmount bandwidth={data.data.total_bw} />
                </Badge>
              </td>
            </tr>
            <tr>
              <th>OSIA Allocated</th>
              <td>
                <Badge bg="danger" className="btn-gradient">
                  <BandwidthAmount bandwidth={data.data.osia_bw} />
                </Badge>
              </td>
            </tr>
            <tr>
              <th>DIA Allocated</th>
              <td>
                <Badge bg="danger" className="btn-gradient">
                  <BandwidthAmount bandwidth={data.data.dia_bw} />
                </Badge>
              </td>
            </tr>
            <tr
              {...(data.data_source === "inventory"
                ? { onClick: togglePrimary, style: { cursor: "pointer" } }
                : null)}
            >
              <th>Primary Circuit</th>
              <td>
                {data.data.primary_circuit?.cid || data.data.primary_circuit}
                {data.data_source === "inventory" && (
                  <>
                    {showPrimary ? (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        className="float-end mt-1"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-end mt-1"
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
            {showPrimary && data.data_source === "inventory" && (
              <>
                <tr style={{ backgroundColor: "#F7F7F7" }}>
                  <th className="ps-4 text-sm" style={{ color: "gray" }}>
                    CDR
                  </th>
                  <td className="text-sm" style={{ color: "gray" }}>
                    <BandwidthAmount
                      bandwidth={data.data.primary_circuit.cdr}
                    />
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#F7F7F7" }}>
                  <th className="ps-4 text-sm" style={{ color: "gray" }}>
                    Provider
                  </th>
                  <td className="text-sm" style={{ color: "gray" }}>
                    {data.data.primary_circuit.provider}
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#F7F7F7" }}>
                  <th className="ps-4 text-sm" style={{ color: "gray" }}>
                    Type
                  </th>
                  <td className="text-sm" style={{ color: "gray" }}>
                    {data.data.primary_circuit.type}
                  </td>
                </tr>
              </>
            )}
            {data.data.secondary_circuit && data.data.secondary_circuit.cid && (
              <tr
                {...(data.data_source === "inventory"
                  ? { onClick: toggleSecondary, style: { cursor: "pointer" } }
                  : null)}
              >
                <th>Secondary Circuit</th>
                <td>
                  {data.data.secondary_circuit?.cid ||
                    data.data.secondary_circuit}
                  {data.data_source === "inventory" && (
                    <>
                      {showSecondary ? (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className="float-end mt-1"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="float-end mt-1"
                        />
                      )}
                    </>
                  )}
                </td>
              </tr>
            )}

            {showSecondary &&
              data.data_source === "inventory" &&
              data.data.secondary_circuit.cid && (
                <>
                  <tr style={{ backgroundColor: "#F7F7F7" }}>
                    <th className="ps-4 text-sm" style={{ color: "gray" }}>
                      CDR
                    </th>
                    <td className="text-sm" style={{ color: "gray" }}>
                      <BandwidthAmount
                        bandwidth={data.data.secondary_circuit.cdr}
                      />
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#F7F7F7" }}>
                    <th className="ps-4 text-sm" style={{ color: "gray" }}>
                      Provider
                    </th>
                    <td className="text-sm" style={{ color: "gray" }}>
                      {data.data.secondary_circuit.provider}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#F7F7F7" }}>
                    <th className="ps-4 text-sm" style={{ color: "gray" }}>
                      Type
                    </th>
                    <td className="text-sm" style={{ color: "gray" }}>
                      {data.data.secondary_circuit.type}
                    </td>
                  </tr>
                </>
              )}
            {data.data.tertiary_circuit && data.data.tertiary_circuit.cid && (
              <tr
                {...(data.data_source === "inventory"
                  ? { onClick: toggleTertiary, style: { cursor: "pointer" } }
                  : null)}
              >
                <th>Tertiary Circuit</th>
                <td>
                  {data.data.tertiary_circuit?.cid ||
                    data.data.tertiary_circuit}
                  {data.data_source === "inventory" && (
                    <>
                      {showTertiary ? (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className="float-end mt-1"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="float-end mt-1"
                        />
                      )}
                    </>
                  )}
                </td>
              </tr>
            )}
            {showTertiary &&
              data.data_source === "inventory" &&
              data.data.tertiary_circuit.cid && (
                <>
                  <tr style={{ backgroundColor: "#F7F7F7" }}>
                    <th className="ps-4 text-sm" style={{ color: "gray" }}>
                      CDR
                    </th>
                    <td className="text-sm" style={{ color: "gray" }}>
                      <BandwidthAmount
                        bandwidth={data.data.tertiary_circuit.cdr}
                      />
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#F7F7F7" }}>
                    <th className="ps-4 text-sm" style={{ color: "gray" }}>
                      Provider
                    </th>
                    <td className="text-sm" style={{ color: "gray" }}>
                      {data.data.tertiary_circuit.provider}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#F7F7F7" }}>
                    <th className="ps-4 text-sm" style={{ color: "gray" }}>
                      Type
                    </th>
                    <td className="text-sm" style={{ color: "gray" }}>
                      {data.data.tertiary_circuit.type}
                    </td>
                  </tr>
                </>
              )}
            <tr style={{ borderBottom: "none" }}>
              <th style={{ borderBottom: "none" }}>
                <span className="text-sm" style={{ color: "silver" }}>
                  Data Source
                </span>
              </th>
              <td style={{ borderBottom: "none" }}>
                <span className="text-sm" style={{ color: "silver" }}>
                  {data.data_source.charAt(0).toUpperCase() +
                    data.data_source.slice(1)}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default BuildingNetworkInfo;
