import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";

import counterReducer from "./slices/counter";
import landlordReducer from "./slices/landlordswitcher";
import tenantNetworkReducer from "./slices/tenantnetwork";
import coordinatesReducer from "./slices/coordinates";
import customersReducer from "./slices/customers";
import filtersReducer from "./slices/filters";
import refreshDataReducer from "./slices/refreshData";
import coreMonitoringReducer from "./slices/coremonitoring";
import pageLoadingReducer from "./slices/pageloading";
import accountReducer from "./slices/accountswitcher";
import buildingsReducer from "./slices/buildingsSlice";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    buildings: buildingsReducer,
    landlordswitcher: landlordReducer,
    accountswitcher: accountReducer,
    coordinates: coordinatesReducer,
    customers: customersReducer,
    searchfilters: filtersReducer,
    refreshdata: refreshDataReducer,
    coremonitoring: coreMonitoringReducer,
    pageloading: pageLoadingReducer,
    tenantnetwork: tenantNetworkReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
