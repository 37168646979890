import React, { useEffect, useState } from "react";
//import { useTranslation } from "react-i18next"; // For Search
import { Navbar, Nav } from "react-bootstrap"; // Search: Button, Form, InputGroup
//import { SIDEBAR_BEHAVIOR } from "../../constants";
//import { useSelector } from "react-redux";
//import { useDispatch } from "react-redux";
//import { setLandlord } from "../../redux/slices/landlordswitcher";
import useAuth from "../../hooks/useAuth";

import { AlertCircle, Bell, Inbox, UserPlus } from "react-feather"; // Search: Search

import useSidebar from "../../hooks/useSidebar";

import IsAllowed from "../IsAllowed";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
//import NavbarLandlordSwitcher from "./NavbarLandlordSwitcher";
//import LandlordSwitcherItem from "./NavbarLandlordSwitcherItem";
//import NavbarLanguages from "./NavbarLanguages"; // LanguageSwitcher
import NavbarUser from "./NavbarUser";
import NavbarAccountSwitcher from "./NavbarAccountSwitcher";

//import workspacelogo from "../../assets/img/logos/onw.webp";
/*import britishlandlogo from "../../assets/img/logos/british-land-logo-transparent.png";
import gpelogo from "../../assets/img/logos/gpe.svg.png";
import myologo from "../../assets/img/logos/myo.png";
import sohoworkslogo from "../../assets/img/logos/sohoworks-transparent.png";
import thestorexlogo from "../../assets/img/logos/thestorex-transparent.png";*/
//import canvasofficeslogo from "../../assets/img/logos/canvas-transparent.png"; /*
//import bigbuildingsinc from "../../assets/img/logos/bigbuildingsinc-logo.png";*/
//import gwslogo from "../../assets/img/logos/gws.png";

import axios from "axios";
import { apiConfig } from "../../config";
import NavbarNewLandlordSwitcher from "./NavbarNewLandlordSwitcher";

//import { ReactComponent as Loader } from "../../assets/img/layer8-animated-loader.svg";
import LogoLoader from "../LogoLoader";

// const landlords = [
//   {
//     landlordname: "Workspace Group",
//     landlordlogo: workspacelogo,
//     landlordid: 2,
//     ctids: [2, 4, 29, 30, 63, 64],
//   },
//   {
//     landlordname: "Great Western Studios",
//     landlordlogo: gwslogo,
//     landlordid: 3,
//     ctids: [8, 67],
//   },
//   /*{
//     landlordname: "British Land/Storey",
//     landlordlogo: britishlandlogo,
//     landlordid: 19,
//   },
//   {
//     landlordname: "Great Portland Estates (GPE)",
//     landlordlogo: gpelogo,
//     landlordid: 34,
//   },
//   {
//     landlordname: "Landsec/LS MYO",
//     landlordlogo: myologo,
//     landlordid: 20,
//   },
//   {
//     landlordname: "Soho Works",
//     landlordlogo: sohoworkslogo,
//     landlordid: 39,
//   },
//   {
//     landlordname: "The Store X",
//     landlordlogo: thestorexlogo,
//     landlordid: 40,
//   },*/
//   {
//     landlordname: "Canvas/RNR Property",
//     landlordlogo: canvasofficeslogo,
//     landlordid: 24,
//     ctids: [57],
//   } /*
//   {
//     landlordname: "Big Buildings Inc (Demo)",
//     landlordlogo: bigbuildingsinc,
//     landlordid: 36,
//   },*/,
// ];

/*const messages = [
  {
    name: "Ashley Briggs",
    avatar: avatar5,
    description: "Nam pretium turpis et arcu. Duis arcu tortor.",
    time: "15m ago",
  },
  {
    name: "Chris Wood",
    avatar: avatar1,
    description: "Curabitur ligula sapien euismod vitae.",
    time: "2h ago",
  },
  {
    name: "Stacie Hall",
    avatar: avatar4,
    description: "Pellentesque auctor neque nec urna.",
    time: "4h ago",
  },
  {
    name: "Bertha Martin",
    avatar: avatar3,
    description: "Aenean tellus metus, bibendum sed, posuere ac, mattis non.",
    time: "5h ago",
  },
];*/

/* -- Search box
      const { t } = useTranslation();
      <Form inline="true" className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control placeholder={t("Search")} aria-label="Search" />
          <Button variant="">
            <Search className="feather" />
          </Button>
        </InputGroup>
      </Form> 

*/

const NavbarComponent = () => {
  const { behavior, setBehavior } = useSidebar();
  var toggleSidebar = behavior === "compact" ? "sticky" : "compact";
  // REDUX
  /*const auth = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("Auth", auth);
    dispatch(
      setLandlord({
        id: auth?.user.landlordid,
        name: "Workspace",
        logo: "",
      })
    );
  }, [auth, dispatch]);*/
  // Set landlord name and logo from above
  // const [landlordDetails, setLandlordDetails] = useState({});
  // const selectedLandlord = useSelector((state) => state.landlordswitcher);
  // useEffect(() => {
  //   let selectedLandlordDetails = landlords.filter(
  //     (landlord) => landlord.landlordid === selectedLandlord.landlordid
  //   );
  //   setLandlordDetails(selectedLandlordDetails);
  // }, [selectedLandlord]);
  // Get notifications for logged in user
  const { user, userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [notificationsList, setNotificationsList] = useState([]);
  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (user && isAuthenticated) {
      axios
        .get(apiConfig.usersApiUrl + "notifications/", axiosConfig)
        .then((response) => {
          setNotificationsList(response.data);
        })
        .catch((err) => {
          console.log("Notifications list error", err);
        });
    }
  }, [user, bearerToken, isAuthenticated]);

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setBehavior(toggleSidebar);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      {user && isAuthenticated ? (
        <Navbar.Collapse>
          <Nav className="navbar-align">
            <IsAllowed to="view:landlordswitcher">
              {/* <div className="nav-item m-2">
              Viewing: &nbsp;&nbsp;
              <img
                height={25}
                src={landlordDetails[0]?.landlordlogo}
                alt={landlordDetails[0]?.landlordname}
              />
            </div>
            <NavbarLandlordSwitcher header="Switch Landlord" icon={Globe}>
              {landlords.map((item, key) => {
                return (
                  <LandlordSwitcherItem
                    key={key}
                    landlordname={item.landlordname}
                    landlordlogo={item.landlordlogo}
                    landlordid={item.landlordid}
                    ctids={item.ctids}
                  />
                );
              })}
            </NavbarLandlordSwitcher> */}
              <NavbarNewLandlordSwitcher />
            </IsAllowed>
            <NavbarAccountSwitcher />
            <IsAllowed to="view:notifications">
              <NavbarDropdown
                header="New Notifications"
                footer="Show all notifications"
                icon={Bell}
                count={notificationsList.unreadCount}
                showBadge={notificationsList.unreadCount > 0 ? true : false}
              >
                {notificationsList?.notifications
                  ?.slice(0, 5)
                  .map((item, key) => {
                    let icon = <Bell size={18} className="text-success" />;
                    let unread = "";

                    if (item.type === "important") {
                      icon = <AlertCircle size={18} className="text-danger" />;
                    }

                    if (item.type === "tickets.newTicket") {
                      icon = <Inbox size={18} className="text-primary" />;
                    }

                    if (item.type === "users.newUser") {
                      icon = <UserPlus size={18} className="text-success" />;
                    }

                    if (item.unread === 1) {
                      unread = "notification-unread";
                    }

                    return (
                      <NavbarDropdownItem
                        key={key}
                        notification_id={item.id}
                        icon={icon}
                        title={item.title}
                        description={item.description}
                        time={item.time}
                        unread={unread}
                        link={item.link}
                      />
                    );
                  })}
              </NavbarDropdown>
            </IsAllowed>
            <NavbarUser />
          </Nav>
        </Navbar.Collapse>
      ) : (
        <div height="20px" width="auto" className="navbar-align">
          <LogoLoader loaderClasses={"float-end"} />
        </div>
      )}
    </Navbar>
  );
};

export default NavbarComponent;
