import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {
  Badge,
  Card,
  Col,
  Container,
  Row,
  Table,
  Breadcrumb,
} from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import GetSupport from "../../components/GetSupport";
import KeyContactsList from "../../components/KeyContacts";
import ColumnSortingTable from "../../components/tables/ColumnSortingTable";
import Map from "./Map";
import { tableColumns } from "./data.js";
import useAuth from "../../hooks/useAuth";
import IsAllowed from "../../components/IsAllowed";

import { useSelector } from "react-redux";
import { apiConfig } from "../../config";
//import { ReactComponent as LogoLoader } from "../../assets/img/layer8-animated-loader.svg";
import LogoLoader from "../../components/LogoLoader.js";

const BuildingProfile = ({
  name,
  status,
  description,
  created,
  golive,
  keycontacts,
}) => {
  return (
    <Card>
      <Card.Header className="mb-0 pb-0">
        <Card.Title className="mb-0">
          <h1 className="mb-0 pb-0">{name}</h1>
          <Badge
            className={
              status === "Live Building"
                ? "my-2 btn-gradient inline"
                : "my-2 inline"
            }
            bg="success"
          >
            {status}
          </Badge>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <h5>Building Overview:</h5>
        <p className="mb-4">{description}</p>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-4">
              <h5>Created Date</h5>
              <p>{moment(created).local().format("DD-MM-YYYY")}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-4">
              <h5>Go-Live Date</h5>
              <p>{moment(golive).local().format("DD-MM-YYYY")}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-4">
              <h5>Key Contacts</h5>
              <KeyContactsList keycontacts={keycontacts} />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const Rooms = () => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { bid } = useParams();
  const { userAccessToken, isAuthenticated } = useAuth();
  const [roomsData, setRoomsData] = useState([]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    const fetchData = async () => {
      if (isAuthenticated) {
        const customersApi = `${apiConfig.connectedApiUrl}organisations/?operator_id=${selectedLandlord.landlordid}`;
        const roomsApi = `${apiConfig.connectedApiUrl}rooms/withtenancies?page_size=1000&building_id=${bid}`;

        try {
          const [customersResponse, roomsResponse] = await Promise.all([
            axios.get(customersApi, axiosConfig),
            axios.get(roomsApi, axiosConfig),
          ]);

          const roomsList = roomsResponse.data.rooms.items.map((room) => {
            const customerMatch = customersResponse.data.find(
              (customer) =>
                customer.external_id === room?.tenancies[0]?.tenant_id
            );

            return {
              room_number: room.room_number,
              tenant: customerMatch?.organisation_name || null,
              account_number: customerMatch?.external_id || null,
              floor: "--",
              area_squarefeet: room.area_squarefeet,
            };
          });

          if (isMounted) {
            setLoading(false);
            setRoomsData(roomsList);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [bearerToken, bid, selectedLandlord.landlordid, isAuthenticated]);

  return (
    <Card>
      <Card.Header className="mb-0 pb-0">
        <Card.Title className="mb-0">
          <h3 className="mb-0 pb-0">Rooms</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <LogoLoader className="d-block m-auto p-4" />
        ) : (
          <ColumnSortingTable
            columns={tableColumns}
            data={roomsData}
            hiddenColumns={["account_number"]}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const AccountManager = ({ data }) => {
  const [accountManager, setAccountManager] = useState();

  useEffect(() => {
    const accountManagerContact = data?.find(
      (contact) => contact.type === "Account Manager"
    );
    setAccountManager(accountManagerContact);
  }, [data]);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Account Manager</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="g-0">
          <Col sm="3" xl="12" className="col-xxl-3 text-center">
            <img
              src={accountManager?.avatar}
              width="64"
              height="64"
              className="rounded-circle mt-2"
              alt={accountManager?.name}
            />
          </Col>
          <Col sm="9" xl="12" className="col-xxl-9">
            <strong>{accountManager?.name}</strong>
            <p>
              {accountManager?.name} is the Account Manager for this building.
              You can contact them using the details below.
            </p>
          </Col>
        </Row>

        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>Name</th>
              <td>{accountManager?.name}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{accountManager?.email}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const fetchBuildingData = async (bid, bearerToken) => {
  const axiosConfig = {
    headers: { Authorization: bearerToken },
  };

  const connectedApi = `${apiConfig.connectedApiUrl}buildings/${bid}`;
  const connectedApiRequest = axios.get(connectedApi, axiosConfig);
  const [connectedApiResponse] = await axios.all([connectedApiRequest]);

  const contacts = [
    {
      name: "Bruce Wayne",
      email: "bruce@wayneenterprises.com",
      phone: "+44 1234 567890",
      type: "Account Manager",
      avatar:
        "https://i.pinimg.com/280x280_RS/39/fd/09/39fd09a3f0962e5b7b4a3ccbf5a61057.jpg",
    },
  ];

  return [connectedApiResponse.data.building, contacts];
};

const Building = () => {
  const { bid } = useParams();
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  const [buildingData, setBuildingData] = useState([]);
  const [keyContacts, setKeyContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    if (isAuthenticated) {
      fetchBuildingData(bid, bearerToken)
        .then(([building, contacts]) => {
          if (isMounted) {
            setLoading(false);
            setBuildingData(building);
            setKeyContacts(contacts);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [bearerToken, bid, isAuthenticated]);

  return (
    <Container fluid className="p-0">
      <Helmet title="Building Profile" />
      <Breadcrumb className="float-end mt-2">
        <Breadcrumb.Item href="/dashboard/default">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/buildings/portfolio">Portfolio</Breadcrumb.Item>
        <Breadcrumb.Item active>Building Profile</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="h3 mb-3">
        Building Profile
        <OffcanvasHelp
          id="buildingprofile"
          name="Building Profile"
          scroll
          backdrop
        />
      </h1>
      <div className="clearfix"></div>
      {loading ? (
        <Container
          fluid
          className="d-flex justify-content-center align-items-center long-loader-container"
        >
          <div className="text-center">
            <LogoLoader className="d-block m-auto p-5" width="0.1vw" />
          </div>
        </Container>
      ) : (
        <Row>
          <Col xl="8">
            <BuildingProfile
              name={buildingData.building_name}
              status={buildingData.status}
              description={buildingData.description}
              keycontacts={keyContacts}
              golive={buildingData.go_live_date}
              created={buildingData.created_at}
            />
            <IsAllowed to="view:building:profile:rooms">
              <Rooms />
            </IsAllowed>
          </Col>
          <Col xl="4">
            <AccountManager data={keyContacts} />
            <Map
              address={`${buildingData.address_1}, ${buildingData.postcode}`}
              name={buildingData.building_name}
            />
            <GetSupport type="commercial" />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Building;
