import React from "react";

import SidebarNavSection from "./SidebarNavSection";
import IsDisabledForLandlordId from "../IsDisabledForLandlordId";

const SidebarNav = ({ items }) => {
  return (
    <ul className="sidebar-nav">
      {items &&
        items.map((item) => (
          <IsDisabledForLandlordId
            disabledForLandlordIds={item.disabledForLandlordIds}
          >
            <SidebarNavSection
              key={item.title}
              pages={item.pages}
              title={item.title}
              permissionName={item.permissionName}
            />
          </IsDisabledForLandlordId>
        ))}
    </ul>
  );
};

export default SidebarNav;
