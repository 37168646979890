import React, { useState, useContext } from "react";
import { Col, Form, Row, Button, Modal, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faTrash,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
//import { ReactComponent as LogoLoader } from "../../assets/img/layer8-animated-loader.svg";
import LogoLoader from "../../components/LogoLoader";

import NotFound from "../../components/NotFound";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import Select from "react-select";

import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshData } from "../../redux/slices/refreshData";

import axios from "axios";
import { apiConfig } from "../../config";

const NotificationsTable = () => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const refreshData = useSelector((state) => state.refreshdata);

  const dispatch = useDispatch();

  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const subscriptionsColumns = [
    {
      Header: "Notify me when",
      accessor: "event_friendly_name",
    },
    {
      Header: "Delivery Method",
      accessor: "delivery_method",
      Cell: (e) =>
        e.value === "email"
          ? "app & email"
          : e.value === "sms"
          ? "app & sms"
          : e.value === "*"
          ? "app, email & sms"
          : "app",
    },
    {
      Header: "Filters",
      accessor: "event_filters",
      Cell: (e) =>
        JSON.stringify(e.value) === "null"
          ? "0 filters"
          : e.value.length + " filters",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <FontAwesomeIcon
            icon={faTrash}
            className="ms-2"
            onClick={() => {
              deleteSubscription(row.original.id);
              setTimeout(() => {
                dispatch(setRefreshData());
              }, 2000);
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];

  const deleteSubscription = (subscriptionId) => {
    // Use axios to delete the credential
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .delete(
        `${apiConfig.usersApiUrl}notifications/subscriptions/${subscriptionId}`,
        axiosConfig
      )
      .then(function (response) {
        if (response.status === 200) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(error);
      });
    // Launch notyf based on the API response
  };

  // Load list of notification subscriptions
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "notifications/subscriptions", axiosConfig)
      .then((response) => {
        setSubscriptionsList(response.data);
        setLoading(false);
        setNoData(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [bearerToken, refreshData]);

  // Modal config for "Configure Wireless Network" Action
  const initOpenModals = () => {
    let modals = {};
    return modals;
  };
  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };
  /*const passData = (data) => {
    setPassedData(data);
  };*/

  const addSubscription = () => {
    console.log("Adding subscription");
  };

  // Form States for Create Notification Subscription Form
  const [eventType, setEventType] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [filterField, setFilterField] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const handleCreateSubscriptionSubmit = (event) => {
    event.preventDefault();
    const createSubscriptionBody = {
      eventType: eventType,
      deliveryMethod: deliveryMethod,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(
        apiConfig.usersApiUrl + "notifications/subscriptions",
        createSubscriptionBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 201) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((error) => {
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(error);
      });
    setDeliveryMethod("");
    setEventType("");
  };

  // Load list of event types for the create notification subscription form
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [eventTypeMetadataList, setEventTypeMetadataList] = useState({});
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "notifications/eventTypes", axiosConfig)
      .then((response) => {
        let eventTypeList = [];
        let eventTypeMetadataList = {};
        response.data.forEach((eventType) => {
          let eventTypeItem = {
            value: eventType.event_type,
            label: eventType.event_friendly_name,
          };
          let eventTypeMetadata = [];
          Object.keys(
            eventType.event_schema.properties.metadata.properties
          ).forEach((field) => {
            let fieldObject = { value: field, label: field };
            eventTypeMetadata.push(fieldObject);
          });
          eventTypeMetadataList[eventType.event_type] = eventTypeMetadata;
          eventTypeList.push(eventTypeItem);
        });
        setEventTypeMetadataList(eventTypeMetadataList);
        setEventTypeOptions(eventTypeList);
        console.log("metadata list", eventTypeMetadataList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [bearerToken]);

  // State for subscription filters in modal
  let [filtersList, setFiltersList] = useState([]);

  const handleAddFilter = () => {
    let filter = {
      accessor: filterField,
      operator: "EQ",
      value: filterValue,
    };
    setFiltersList([...filtersList, filter]);
    console.log(filtersList);
    setFilterField("");
    setFilterValue("");
  };
  const handleRemoveFilter = (index) => {
    setFiltersList(filtersList.filter((_, i) => i !== index));
  };
  return (
    <>
      {loading && (
        <>
          <LogoLoader className="d-block m-auto p-4" />
        </>
      )}
      {!loading && noData && <NotFound />}
      {!loading && !noData && (
        <>
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => toggle("addSubscription")}
          >
            <FontAwesomeIcon icon={faBell} className="me-1" /> Add Notification
            Subscription
          </Button>
          <ColumnFilteringTable
            columns={subscriptionsColumns}
            data={subscriptionsList}
            actions
          />
        </>
      )}

      <Modal
        show={openModals["addSubscription"]}
        onHide={() => toggle("addSubscription")}
        centered
      >
        <Form onSubmit={handleCreateSubscriptionSubmit}>
          <Modal.Header closeButton>
            <b>Notifications:</b>&nbsp; Create Notification Subscription
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              <>
                <Form.Group className="mb-3">
                  <Form.Label>
                    1. What kind of event do you want to receive notifications
                    about?
                  </Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={eventTypeOptions}
                    onChange={(e) => setEventType(e.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    2. How do you want to receive notifications about these
                    events?
                  </Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={[
                      { label: "In-App Only", value: "app" },
                      { label: "App & Email", value: "email" },
                      { label: "App & SMS", value: "sms" },
                      { label: "App, Email & SMS", value: "*" },
                    ]}
                    onChange={(e) => setDeliveryMethod(e.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    3. Add filters to this subscription to receive notifications
                    only when all conditions are matched.
                  </Form.Label>
                  <Row className="p-1 mb-3">
                    <Col xs="5">
                      <Select
                        className="react-select-container w-100"
                        classNamePrefix="react-select"
                        options={eventTypeMetadataList[eventType]}
                        placeholder="Select field"
                        onChange={(e) => setFilterField(e.value)}
                      />
                    </Col>
                    <Col xs="2" className="text-center text-lg">
                      is equal to
                    </Col>
                    <Col xs="4">
                      <Form.Control
                        type="text"
                        placeholder="filter value"
                        onChange={(e) => setFilterValue(e.target.value)}
                      />
                    </Col>
                    <Col xs="1">
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        className="text-lg align-middle m-auto text-primary"
                        onClick={() => handleAddFilter()}
                      />
                    </Col>
                  </Row>
                  {filtersList.map((item, key) => {
                    return (
                      <Alert
                        variant="secondary"
                        onClose={() => handleRemoveFilter(key)}
                        dismissible
                      >
                        <div className="alert-message">
                          <strong>{item.accessor}</strong>{" "}
                          {item.operator === "EQ" ? "is equal" : "is not equal"}{" "}
                          to <strong>"{item.value}"</strong>
                        </div>
                      </Alert>
                    );
                  })}
                </Form.Group>
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                toggle("addSubscription");
                setFiltersList([]);
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {
                toggle("addSubscription");
                addSubscription();
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
              }}
            >
              Create Subscription
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NotificationsTable;
