import React, { useState, useEffect } from "react";

import { Modal, Row, Col, Button } from "react-bootstrap";

import { Formik, Form } from "formik";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

import Stepper from "./Stepper";
import FormPage from "./FormPage";

import useAuth from "../../hooks/useAuth";

const MultiPageForm = ({ config, openModals, toggle }) => {
  const [page, setPage] = useState(1);
  const [myFormData, setMyFormData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useAuth();

  const [submissionStatus, setSubmissionStatus] = useState({
    isLoading: false,
    result: null,
    message: "",
    tasks: [],
  });

  useEffect(() => {
    console.log("submissionStatus:", submissionStatus);
  }, [submissionStatus]);

  // const [tasksStatus, setTasksStatus] = useState([]);
  // const updateTaskStatus = (taskName, status, message) => {
  //   console.log("Updating task status:", taskName, status, message);
  //   setSubmissionStatus((prevStatus) => {
  //     const currentTasks = prevStatus.tasks || [];
  //     const newTasks = [...currentTasks];
  //     const taskIndex = newTasks.findIndex((task) => task.name === taskName);
  //     if (taskIndex > -1) {
  //       // Update an existing task
  //       newTasks[taskIndex] = { ...newTasks[taskIndex], status, message };
  //     } else {
  //       // Add a new task
  //       newTasks.push({ name: taskName, status, message });
  //     }
  //     return { ...prevStatus, tasks: newTasks };
  //   });
  // };

  // const updateTaskStatus = (taskName, status, message) => {
  //   setSubmissionStatus((prevStatus) => {
  //     const newTasks = prevStatus.tasks.map((task) =>
  //       task.name === taskName ? { ...task, status, message } : task
  //     );

  //     // Check if all tasks have completed (either succeeded or failed)
  //     const allTasksCompleted = newTasks.every(
  //       (task) => task.status === "succeeded" || task.status === "failed"
  //     );

  //     // Determine overall success or failure if all tasks have completed
  //     if (allTasksCompleted) {
  //       const allSucceeded = newTasks.every(
  //         (task) => task.status === "succeeded"
  //       );
  //       const anyFailed = newTasks.some((task) => task.status === "failed");

  //       return {
  //         ...prevStatus,
  //         isLoading: false,
  // result: allSucceeded ? "success" : anyFailed ? "partial" : "failure",
  // message: allSucceeded
  //   ? "All tasks completed successfully."
  //   : anyFailed
  //   ? "Some tasks failed."
  //   : "All tasks have failed.",
  //         tasks: newTasks,
  //       };
  //     } else {
  //       // Not all tasks have completed yet, keep isLoading true
  //       return {
  //         ...prevStatus,
  //         isLoading: true,
  //         tasks: newTasks,
  //       };
  //     }
  //   });
  // };

  const updateTaskStatus = (taskName, status, message) => {
    setSubmissionStatus((prevStatus) => {
      let taskFound = false;
      const newTasks = prevStatus.tasks.map((task) => {
        if (task.name === taskName) {
          taskFound = true;
          return { ...task, status, message };
        }
        return task;
      });

      // If the task was not found, add it as a new task.
      if (!taskFound) {
        newTasks.push({ name: taskName, status, message });
      }

      // Check if all tasks have completed (either succeeded or failed)
      const allTasksCompleted = newTasks.every(
        (task) => task.status === "succeeded" || task.status === "failed"
      );

      // Determine overall success or failure if all tasks have completed
      if (allTasksCompleted) {
        const allSucceeded = newTasks.every(
          (task) => task.status === "succeeded"
        );
        const anyFailed = newTasks.some((task) => task.status === "failed");

        return {
          ...prevStatus,
          isLoading: false,
          result: allSucceeded ? "success" : anyFailed ? "partial" : "failure",
          message: allSucceeded
            ? "All tasks completed successfully."
            : anyFailed
            ? "Some tasks failed."
            : "All tasks have failed.",
          tasks: newTasks,
        };
      } else {
        // Not all tasks have completed yet, keep isLoading true
        return {
          ...prevStatus,
          isLoading: true,
          tasks: newTasks,
        };
      }
    });
  };

  const handleSubmitClick = (values, formikBag) => {
    // Navigate to the final "Submission" step and start loading
    setPage(config.steps.length); // Navigate immediately
    setSubmissionStatus({
      isLoading: true,
      result: null,
      message: "",
      tasks: [],
    });

    // Perform the submission asynchronously
    const finalFormData = { ...myFormData, ...values };
    config
      .handleSubmit(finalFormData, {
        setError,
        setSuccess,
        token,
        updateTaskStatus,
        setSubmissionStatus,
      })
      .then(() => {
        // setSubmissionStatus((prevStatus) => ({
        //   ...prevStatus,
        //   isLoading: false,
        //   result: "success",
        //   message: "Submission successful!",
        // }));
      })
      .catch((error) => {
        // setSubmissionStatus((prevStatus) => ({
        //   ...prevStatus,
        //   isLoading: false,
        //   result: "failure",
        //   message: "Submission failed. Please try again.",
        // }));
      })
      .finally(() => formikBag.setSubmitting(false)); // Ensure to stop the loading indicator
  };

  const currentStepConfig = config.steps[page - 1];
  const isLastStep = page === config.steps.length - 1; // Adjusting for zero-based index
  const isSubmissionStep = page === config.steps.length; // New submission step

  const handlePrev = () => {
    setPage(Math.max(1, page - 1));
  };
  const handleNext = (currentValues, formikBag) => {
    if (isLastStep) {
      formikBag.submitForm();
    } else {
      const updatedFormData = { ...myFormData, ...currentValues };
      setMyFormData(updatedFormData);
      setPage(Math.min(config.steps.length, page + 1));
    }
  };

  const handleSelect = (setFieldValue) => (name, option) => {
    setFieldValue(name, option.value);
  };

  const getInitialValuesForStep = (step) => {
    const stepConfig = config.steps[step - 1];
    const initialValues = {};
    stepConfig.fields.forEach((field) => {
      const shouldDisplay = field.condtion ? field.condtion(myFormData) : true;
      if (shouldDisplay) {
        initialValues[field.name] = myFormData[field.name] || "";
      }
    });
    return initialValues;
  };

  useEffect(() => {
    console.log("Page has updated to:", page);
  }, [page]);

  useEffect(() => {
    console.log("myFormData has updated to:", myFormData);
  }, [myFormData]);

  useEffect(() => {
    console.log("setSuccess:", success);
  }, [success]);

  return (
    <Modal
      show={openModals[config.name]}
      onHide={() => {
        toggle(config.name);
        setPage(1);
        setMyFormData({});
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton style={{ border: "0" }}>
        <Modal.Title className="ms-4 mt-1 text-muted fs-5">
          {config.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="p-3">
          <Col md="4" className="px-3">
            <Stepper
              steps={config.steps}
              currentPage={page}
              submissionStatus={submissionStatus}
              isSubmissionStep={isSubmissionStep}
            />
          </Col>
          <Col md="8" className="px-6">
            <div className="d-flex flex-row-fluid justify-content-center">
              <Formik
                key={page}
                initialValues={getInitialValuesForStep(page, config)}
                validationSchema={currentStepConfig.validationSchema}
                onSubmit={(values, formikBag) => {
                  if (isLastStep) {
                    // If it's the "Confirm Details" step
                    handleSubmitClick(values, formikBag);
                  } else {
                    // Existing logic for navigating through form steps
                    handleNext(values);
                  }
                }}
                enableReinitialize={true}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  onSubmit,
                  values,
                  validateField,
                  setFieldTouched,
                }) => (
                  <Form className="pb-5 w-100">
                    <FormPage
                      formConfig={config}
                      stepConfig={config.steps[page - 1]}
                      handleChange={handleChange}
                      handleSelect={handleSelect(setFieldValue)}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      values={values}
                      validateField={validateField}
                      setFieldTouched={setFieldTouched}
                      myFormData={myFormData}
                      setMyFormData={setMyFormData}
                      isSubmissionStep={isSubmissionStep}
                      error={error}
                      success={success}
                      isLastStep={isLastStep}
                      submissionStatus={submissionStatus}
                      // tasksStatus={tasksStatus}
                    />
                    <div className="d-flex justify-content-between pt-10">
                      <Button
                        onClick={handlePrev}
                        className="btn btn-lg btn-light"
                        disabled={page === 1 || submissionStatus.isLoading}
                      >
                        <FontAwesomeIcon
                          icon={faArrowLeftLong}
                          className="me-1"
                        />{" "}
                        Previous
                      </Button>
                      {isSubmissionStep ? (
                        <Button
                          onClick={() => {
                            toggle(config.name);
                            setPage(1);
                            setMyFormData({});
                          }}
                          className="btn btn-lg btn-primary"
                          disabled={submissionStatus.isLoading}
                        >
                          Close
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-lg btn-primary"
                          type="submit"
                        >
                          {isLastStep ? "Submit" : "Next"}{" "}
                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="ms-1"
                          />
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MultiPageForm;
