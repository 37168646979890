import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Landing
import Landing from "./pages/landing/Landing";

// Dashboards
import Default from "./pages/dashboards/Default";
// import Tenant from "./pages/dashboards/Tenant";

// Gateway Modules

//import Buildings from "./pages/buildings/Buildings";
import Buildings from "./pages/buildings/BuildingsWithRedux";
import Building from "./pages/buildings/Building";
import NewBuilding from "./pages/buildings/NewBuilding";
import EditBuilding from "./pages/buildings/EditBuilding";

import Customers from "./pages/customers/Customers";
import CustomerProfile from "./pages/customers/Profile";
import { OrgCustomerProfile } from "./pages/customers/Profile";

// import SupportTickets from "./pages/tickets/List";
// import ViewTicket from "./pages/tickets/View";
// import NewTicket from "./pages/tickets/NewTicket";

// import BillingInvoicesList from "./pages/billing/List";

import BuildingNetworks from "./pages/monitoring/SelectBuilding";
import ViewBuildingNetwork from "./pages/monitoring/ViewBuilding";

import EditProfile from "./pages/profile/Edit";

// import WirelessNetworksList from "./pages/wifi/List";

// import TenantNetworks from "./pages/networks/TenantNetworks";

// import CustomerBundlesList from "./pages/bundles/List";
// import ViewBundle from "./pages/bundles/View";

// import ManageCredentials from "./pages/credentials/Dot11";

import SystemSettings from "./pages/settings/Settings";

// Auth
import Page500 from "./pages/auth/Page500";
import MaintenanceMode from "./pages/auth/MaintenanceMode";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
// import Dot11ResetPassword from "./pages/auth/dot11pwreset";

const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "buildings",
    name: "Buildings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "portfolio",
        element: <Buildings />,
      },
      {
        path: "profile/:bid",
        element: <Building />,
      },
      {
        path: "new",
        element: <NewBuilding />,
      },
      {
        path: "edit/:bid",
        element: <EditBuilding />,
      },
    ],
  },
  {
    path: "customers",
    name: "Customers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "profile/:account",
        element: <CustomerProfile />,
      },
      {
        path: "orgProfile",
        element: <OrgCustomerProfile />,
      },
    ],
  },
  // {
  //   path: "tickets",
  //   name: "Support Tickets",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "list",
  //       element: <SupportTickets />,
  //     },
  //     {
  //       path: "view/:ticketid",
  //       element: <ViewTicket />,
  //     },
  //     {
  //       path: "new",
  //       element: <NewTicket />,
  //     },
  //   ],
  // },
  // {
  //   path: "billing",
  //   name: "Billing",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "list",
  //       element: <BillingInvoicesList />,
  //     },
  //   ],
  // },
  {
    path: "monitoring",
    name: "Network Monitoring",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "selectbuilding",
        element: <BuildingNetworks />,
      },
      {
        path: "building/:bid",
        element: <ViewBuildingNetwork />,
      },
    ],
  },
  // {
  //   path: "wifi",
  //   name: "Wireless Networks",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "list",
  //       element: <WirelessNetworksList />,
  //     },
  //   ],
  // },
  // {
  //   path: "credentials",
  //   name: "Credentials",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "dot11",
  //       element: <ManageCredentials />,
  //     },
  //   ],
  // },
  // {
  //   path: "networks",
  //   name: "Tenant Networks",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "tenant",
  //       element: <TenantNetworks />,
  //     },
  //   ],
  // },
  // {
  //   path: "signups",
  //   name: "Customer Signup Forms",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "list",
  //       element: <CustomerBundlesList />,
  //     },
  //     {
  //       path: "view/:signupid",
  //       element: <ViewBundle />,
  //     },
  //   ],
  // },
  {
    path: "system",
    name: "System Settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "settings",
        element: <SystemSettings />,
      },
    ],
  },
  {
    path: "profile",
    name: "User Profile",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "edit",
        element: <EditProfile />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "default",
        element: <Default />,
      },
      // {
      //   path: "tenant",
      //   element: <Tenant />,
      // },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      // {
      //   path: "set-wifi-password",
      //   element: <Dot11ResetPassword />,
      // },
      // {
      //   path: "set-wifi-password/:randomid",
      //   element: <Dot11ResetPassword />,
      // },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "maintenance",
        element: <MaintenanceMode />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
