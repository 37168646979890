// ExpandableRows demo

import { Link } from "react-router-dom";

const tenanciesTableData = [
  {
    tenancy: {
      tenancystart: "2013-01-21",
      tenancyend: "2013-11-01",
      unitsize: 387,
      unit: "BM.2C.05",
      room_id: 2488,
      floor: null,
      building: null,
      building_id: 57,
      building_name: "Barley Mow Centre",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-04-10",
      tenancyend: "2016-09-01",
      unitsize: 404,
      unit: "BM2E.01",
      room_id: 2492,
      floor: null,
      building: null,
      building_id: 57,
      building_name: "Barley Mow Centre",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-04-23",
      tenancyend: "2017-02-17",
      unitsize: 6225,
      unit: "KP.CC.CMO",
      room_id: 3412,
      floor: null,
      building: null,
      building_id: 120,
      building_name: "KP - Canterbury Court",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-04-23",
      tenancyend: "2015-11-25",
      unitsize: null,
      unit: "GB.CMO",
      room_id: 3438,
      floor: null,
      building: null,
      building_id: 140,
      building_name: "Pill Box (The)",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-04-23",
      tenancyend: "2017-02-17",
      unitsize: 225,
      unit: "TA.CMO",
      room_id: 3436,
      floor: null,
      building: null,
      building_id: 142,
      building_name: "Biscuit Factory (The)",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-04-23",
      tenancyend: "2017-02-17",
      unitsize: 225,
      unit: "SW.CMO",
      room_id: 3432,
      floor: null,
      building: null,
      building_id: 141,
      building_name: "Screenworks",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-04-23",
      tenancyend: "2017-02-17",
      unitsize: 225,
      unit: "BM.CMO",
      room_id: 3430,
      floor: null,
      building: null,
      building_id: 57,
      building_name: "Barley Mow Centre",
    },
  },
  {
    tenancy: {
      tenancystart: "2015-09-08",
      tenancyend: "2017-02-17",
      unitsize: null,
      unit: "GI.CMO",
      room_id: 3861,
      floor: null,
      building: null,
      building_id: 151,
      building_name: "Grays Inn Road",
    },
  },
  {
    tenancy: {
      tenancystart: "2016-07-27",
      tenancyend: "2018-06-05",
      unitsize: 601,
      unit: "BM.2.09",
      room_id: 2482,
      floor: null,
      building: null,
      building_id: 57,
      building_name: "Barley Mow Centre",
    },
  },
  {
    tenancy: {
      tenancystart: "2017-06-16",
      tenancyend: "2017-02-17",
      unitsize: 225,
      unit: "WE.CMO",
      room_id: 3418,
      floor: null,
      building: null,
      building_id: 102,
      building_name: "Westbourne Studios",
    },
  },
];

const tenanciesTableColumns = [
  {
    Header: "Building",
    accessor: "tenancy.building_name",
    Cell: (e) => (
      <div className="">
        <Link
          to={
            "/buildings/profile/" +
            (e.row.original?.tenancy?.building_id || null)
          }
        >
          {e.value}
        </Link>
      </div>
    ),
  },
  {
    Header: "Building ID",
    accessor: "tenancy.building_id",
  },
  {
    Header: "Floor",
    accessor: "tenancy.floor",
  },
  {
    Header: "Unit Number",
    accessor: "tenancy.unit",
  },
  {
    Header: "Size (sq ft)",
    accessor: "tenancy.unitsize",
  },
  {
    Header: "Tenancy Start Date",
    accessor: "tenancy.tenancystart",
  },
  {
    Header: "Tenancy End Date",
    accessor: "tenancy.tenancyend",
  },
];

export { tenanciesTableData, tenanciesTableColumns };
