export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  returnUrl: process.env.REACT_APP_AUTH0_RETURN_URL,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const apiConfig = {
  dot11ApiUrl: process.env.REACT_APP_DOT11_API_URL,
  bcsApiUrl: process.env.REACT_APP_BCS_API_URL,
  connectedApiUrl: process.env.REACT_APP_CONNECTED_API_URL,
  coreApiUrl: process.env.REACT_APP_CORE_API_URL,
  customersApiUrl: process.env.REACT_APP_CUSTOMERS_API_URL,
  ticketsApiUrl: process.env.REACT_APP_TICKETS_API_URL,
  ruckusApiUrl: process.env.REACT_APP_RUCKUS_API_URL,
  monitoringApiUrl: process.env.REACT_APP_MONITORING_API_URL,
  usersApiUrl: process.env.REACT_APP_USERS_API_URL,
  symbillApiUrl: process.env.REACT_APP_SYMBILL_API_URL,
  hubspotApiUrl: process.env.REACT_APP_HUBSPOT_API_URL,
};

export const appSettings = {
  maintenanceMode: process.env.REACT_APP_MAINTENANCE_MODE,
};
