import React, { useState } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Modal, Button, Table } from "react-bootstrap";

const colors = [
  {
    name: "Danger",
    value: "danger",
  },
];

const KeyContactsList = ({ keycontacts }) => {
  // Modal config for "Deactivate Building"
  const initOpenModals = () => {
    let modals = {};
    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const [keyContactsIcons, setKeyContactsIcons] = useState();
  const [keyContactsCount, setKeyContactsCount] = useState(0);
  const [keyContactsList, setKeyContactsList] = useState();
  React.useEffect(() => {
    if (keycontacts) {
      const iconItems = keycontacts.slice(0, 3).map((contact) => (
        <span className="me-1">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="tooltip-right">{contact.name}</Tooltip>}
          >
            <img
              src={contact.avatar}
              width="28"
              height="28"
              className="rounded-circle me-2"
              alt={contact.name}
            />
          </OverlayTrigger>
        </span>
      ));
      setKeyContactsIcons(iconItems);
      setKeyContactsCount(keycontacts.length - 3);
      const listItems = keycontacts.map((contact) => (
        <tr>
          <td>
            <img
              src={contact.avatar}
              width="48"
              height="48"
              className="rounded-circle me-2"
              alt={contact.name}
            />{" "}
            {contact.name}
          </td>
          <td>{contact.email}</td>
          <td>{contact.type}</td>
        </tr>
      ));
      setKeyContactsList(listItems);
    }
  }, [keycontacts]);
  return (
    <>
      <div>
        <Link
          onClick={() => toggle("keyContacts")}
          to="#"
          className="d-inline-block text-muted fw-bold ms-2"
        >
          {keyContactsIcons}{" "}
          {keyContactsCount > 0 ? "+" + keyContactsCount + " more" : ""}
        </Link>
      </div>
      <Modal
        show={openModals["keyContacts"]}
        onHide={() => toggle("keyContacts")}
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <b>Building Key Contacts</b>
        </Modal.Header>
        <Modal.Body className="m-3">
          <p className="text-left mb-0">List of building key contacts.</p>
          <Table striped size="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Contact Type</th>
              </tr>
            </thead>
            <tbody>{keyContactsList}</tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle("keyContacts")}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default KeyContactsList;
