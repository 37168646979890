import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLandlord } from "../../redux/slices/landlordswitcher";
import { useNavigate } from "react-router-dom";

import marvellogo from "../../assets/img/logos/marvel.png";

const landlordOptions = {
  wsg: {
    name: "Marvel Universe",
    icon: marvellogo,
    landlordid: 1,
  },
};

const NavbarNewLandlordSwitcher = () => {
  // Set default landlord details from above
  const [landlordDetails, setLandlordDetails] = useState(landlordOptions.wsg);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  React.useEffect(() => {
    let selectedLandlordDetails = Object.values(landlordOptions).find(
      (landlord) => landlord.landlordid === selectedLandlord.landlordid
    );
    if (selectedLandlordDetails) {
      setLandlordDetails(selectedLandlordDetails);
    }
  }, [selectedLandlord]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <span className="m-2">Viewing:</span>
      <Dropdown className="me-2 nav-item" align="end">
        <Dropdown.Toggle as="a" className="nav-link nav-flag nav-landlord">
          {landlordDetails.icon && (
            <img src={landlordDetails.icon} alt={landlordDetails.name} />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: "25vw", overflowY: "auto" }}>
          {Object.keys(landlordOptions).map((landlordKey) => (
            <Dropdown.Item
              key={landlordKey}
              onClick={() => {
                const landlord = landlordOptions[landlordKey];
                dispatch(
                  setLandlord({
                    id: landlord.landlordid,
                    name: landlord.name,
                    logo: landlord.icon,
                  })
                );
                landlord.landlordid === 42
                  ? navigate("/credentials/dot11")
                  : navigate("/dashboard/default");
              }}
            >
              <img
                src={landlordOptions[landlordKey].icon}
                alt="English"
                width="20"
                className="align-middle me-1"
              />
              <span className="align-middle">
                {landlordOptions[landlordKey].name}
              </span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NavbarNewLandlordSwitcher;
