import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faArrowRightLong,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

const MultiPageFormPrototype = ({ openModals, toggle }) => {
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { token } = useAuth();

  const minPages = 1;
  const maxPages = 4;
  const handleNext = () => {
    if (page !== maxPages) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page !== minPages) {
      setPage(page - 1);
    }
  };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleSelect = (selectedOption) => {
    setFormData({ ...formData, [selectedOption.name]: selectedOption.value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${apiConfig.baseUrl}/api/multipage-form`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(response.data.message);
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  console.log(error, success);

  return (
    <Modal
      show={openModals["multiPageForm"]}
      onHide={() => toggle("multiPageForm")}
      size="lg"
      centered
    >
      <Modal.Header closeButton style={{ border: "0" }}>
        {/* <Modal.Title>Create New Tenancy</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Row className="p-3">
          <Col md="4" className="px-3">
            <div className="stepper d-flex flex-column flex-xl-row flex-row-fluid">
              <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                <div className="stepper-nav d-flex flex-column">
                  <div
                    className={
                      "stepper-item " + (page > 1 ? "completed" : "current")
                    }
                  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="stepper-check"
                        />
                        <span className="stepper-number">1</span>
                      </div>
                      <div className="stepper-label">
                        <h4 className="stepper-title">Customer Details</h4>
                        <div className="stepper-desc">
                          Select which customer to move-in
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "stepper-item " +
                      (page > 2 ? "completed" : page === 2 ? "current" : "")
                    }
                  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="stepper-check"
                        />
                        <span className="stepper-number">2</span>
                      </div>
                      <div className="stepper-label">
                        <h4 className="stepper-title">Building & Unit</h4>
                        <div className="stepper-desc">
                          Choose a building and room
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "stepper-item " +
                      (page > 3 ? "completed" : page === 3 ? "current" : "")
                    }
                  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="stepper-check"
                        />
                        <span className="stepper-number">3</span>
                      </div>
                      <div className="stepper-label">
                        <h4 className="stepper-title">Scheduling</h4>
                        <div className="stepper-desc">
                          Select the move-in date and time
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      "stepper-item " +
                      (page > 4 ? "completed" : page === 4 ? "current" : "")
                    }
                  >
                    <div className="stepper-wrapper">
                      <div className="stepper-icon">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="stepper-check"
                        />
                        <span className="stepper-number">4</span>
                      </div>
                      <div className="stepper-label">
                        <h4 className="stepper-title">Review & Complete</h4>
                        <div className="stepper-desc">
                          Confirm the details and submit
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="8" className="px-6">
            <div className="d-flex flex-row-fluid justify-content-center">
              <form className="pb-5 w-100">
                <div className="pb-5 current">
                  <div className="w-100">
                    {/* Start Page 1 */}
                    {page === 1 && (
                      <>
                        <div className="pb-4">
                          <h3 className="text-dark display-6">
                            Customer Details
                          </h3>
                        </div>

                        <div className="fv-row mb-6">
                          <Form.Group>
                            <Form.Label className="fs-4 text-dark">
                              Select Customer
                            </Form.Label>
                            <Select
                              options={[
                                {
                                  label: "Wayne Enterpises",
                                  value: "CRM00001",
                                  name: "customer",
                                },
                                {
                                  label: "Oscorp",
                                  value: "CRM00002",
                                  name: "customer",
                                },
                                {
                                  label: "Daily Planet",
                                  value: "CRM00003",
                                  name: "customer",
                                },
                                {
                                  label: "LexCorp",
                                  value: "CRM00004",
                                  name: "customer",
                                },
                                {
                                  label: "Gringotts Wizarding Bank",
                                  value: "CRM00005",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Xavier's School for Gifted Youngsters",
                                  value: "CRM00006",
                                  name: "customer",
                                },
                                {
                                  label: "Axme Corporation",
                                  value: "CRM00007",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Hogwarts School of Witchcraft and Wizardry",
                                  value: "CRM00008",
                                  name: "customer",
                                },
                                {
                                  label: "Wakanda Design Group",
                                  value: "CRM00009",
                                  name: "customer",
                                },
                                {
                                  label: "Start Industries",
                                  value: "CRM00010",
                                  name: "customer",
                                },
                              ]}
                              onChange={handleSelect}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}
                    {/* End Page 1 */}

                    {/* Start Page 2 */}
                    {page === 2 && (
                      <>
                        <div className="pb-4">
                          <h3 className="text-dark display-6">
                            Building & Unit
                          </h3>
                        </div>

                        <div className="fv-row mb-6">
                          <Form.Group>
                            <Form.Label className="fs-4 text-dark">
                              Select Building
                            </Form.Label>
                            <Select
                              options={[
                                {
                                  label: "Wayne Enterpises",
                                  value: "CRM00001",
                                  name: "customer",
                                },
                                {
                                  label: "Oscorp",
                                  value: "CRM00002",
                                  name: "customer",
                                },
                                {
                                  label: "Daily Planet",
                                  value: "CRM00003",
                                  name: "customer",
                                },
                                {
                                  label: "LexCorp",
                                  value: "CRM00004",
                                  name: "customer",
                                },
                                {
                                  label: "Gringotts Wizarding Bank",
                                  value: "CRM00005",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Xavier's School for Gifted Youngsters",
                                  value: "CRM00006",
                                  name: "customer",
                                },
                                {
                                  label: "Axme Corporation",
                                  value: "CRM00007",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Hogwarts School of Witchcraft and Wizardry",
                                  value: "CRM00008",
                                  name: "customer",
                                },
                                {
                                  label: "Wakanda Design Group",
                                  value: "CRM00009",
                                  name: "customer",
                                },
                                {
                                  label: "Start Industries",
                                  value: "CRM00010",
                                  name: "customer",
                                },
                              ]}
                              onChange={handleSelect}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}
                    {/* End Page 2 */}

                    {/* Start Page 3 */}
                    {page === 3 && (
                      <>
                        <div className="pb-4">
                          <h3 className="text-dark display-6">Scheduling</h3>
                        </div>

                        <div className="fv-row mb-6">
                          <Form.Group>
                            <Form.Label className="fs-4 text-dark">
                              Choose a Date
                            </Form.Label>
                            <Select
                              options={[
                                {
                                  label: "Wayne Enterpises",
                                  value: "CRM00001",
                                  name: "customer",
                                },
                                {
                                  label: "Oscorp",
                                  value: "CRM00002",
                                  name: "customer",
                                },
                                {
                                  label: "Daily Planet",
                                  value: "CRM00003",
                                  name: "customer",
                                },
                                {
                                  label: "LexCorp",
                                  value: "CRM00004",
                                  name: "customer",
                                },
                                {
                                  label: "Gringotts Wizarding Bank",
                                  value: "CRM00005",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Xavier's School for Gifted Youngsters",
                                  value: "CRM00006",
                                  name: "customer",
                                },
                                {
                                  label: "Axme Corporation",
                                  value: "CRM00007",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Hogwarts School of Witchcraft and Wizardry",
                                  value: "CRM00008",
                                  name: "customer",
                                },
                                {
                                  label: "Wakanda Design Group",
                                  value: "CRM00009",
                                  name: "customer",
                                },
                                {
                                  label: "Start Industries",
                                  value: "CRM00010",
                                  name: "customer",
                                },
                              ]}
                              onChange={handleSelect}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}
                    {/* End Page 3 */}

                    {/* Start Page 4 */}
                    {page === 4 && (
                      <>
                        <div className="pb-4">
                          <h3 className="text-dark display-6">
                            Review & Complete
                          </h3>
                        </div>

                        <div className="fv-row mb-6">
                          <Form.Group>
                            <Form.Label className="fs-4 text-dark">
                              Review Details
                            </Form.Label>
                            <Select
                              options={[
                                {
                                  label: "Wayne Enterpises",
                                  value: "CRM00001",
                                  name: "customer",
                                },
                                {
                                  label: "Oscorp",
                                  value: "CRM00002",
                                  name: "customer",
                                },
                                {
                                  label: "Daily Planet",
                                  value: "CRM00003",
                                  name: "customer",
                                },
                                {
                                  label: "LexCorp",
                                  value: "CRM00004",
                                  name: "customer",
                                },
                                {
                                  label: "Gringotts Wizarding Bank",
                                  value: "CRM00005",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Xavier's School for Gifted Youngsters",
                                  value: "CRM00006",
                                  name: "customer",
                                },
                                {
                                  label: "Axme Corporation",
                                  value: "CRM00007",
                                  name: "customer",
                                },
                                {
                                  label:
                                    "Hogwarts School of Witchcraft and Wizardry",
                                  value: "CRM00008",
                                  name: "customer",
                                },
                                {
                                  label: "Wakanda Design Group",
                                  value: "CRM00009",
                                  name: "customer",
                                },
                                {
                                  label: "Start Industries",
                                  value: "CRM00010",
                                  name: "customer",
                                },
                              ]}
                              onChange={handleSelect}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}
                    {/* End Page 4 */}

                    <div className="d-flex justify-content-between pt-10">
                      <Button
                        onClick={handlePrev}
                        className="btn btn-lg btn-light"
                        disabled={page === 1}
                      >
                        <FontAwesomeIcon
                          icon={faArrowLeftLong}
                          className="me-2"
                        />{" "}
                        Previous
                      </Button>
                      <Button
                        onClick={handleNext}
                        className="btn btn-lg btn-primary"
                        style={{ display: page === 4 ? "none" : "block" }}
                      >
                        Next{" "}
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="ms-1"
                        />
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        className="btn btn-lg btn-primary"
                        style={{ display: page === 4 ? "block" : "none" }}
                      >
                        Submit{" "}
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="ms-1"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
        {/* {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {page === 1 && (
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" onChange={handleChange} />
            </Form.Group>
            <Button onClick={handleNext}>Next</Button>
          </Form>
        )}
        {page === 2 && (
          <Form>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <Select
                options={[
                  { label: "India", value: "India", name: "country" },
                  { label: "USA", value: "USA", name: "country" },
                  { label: "UK", value: "UK", name: "country" },
                ]}
                onChange={handleSelect}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                onChange={handleChange}
              />
            </Form.Group>
            <Button onClick={handlePrev}>Previous</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Form>
        )} */}
      </Modal.Body>
    </Modal>
  );
};

export default MultiPageFormPrototype;
