import React from "react";
import { Helmet } from "react-helmet-async";

const MaintenanceMode = () => {
  return (
    <React.Fragment>
      <Helmet title="Maintenance Mode" />
      <div className="text-center">
        <h1 className="display-1 fw-bold">Maintenance Mode</h1>
        <p className="h1">Layer8 Platform is currently in maintenance mode</p>
        <p className="h2 fw-normal mt-3 mb-4">
          The Layer8 application is currently being updated and will be back
          soon.
        </p>
      </div>
    </React.Fragment>
  );
};

export default MaintenanceMode;
