import React from "react";
import { Alert, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faCaretDown,
  faCheck,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import DatePicker from "../DatePicker";
import { format } from "date-fns";
import { Slider } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { quantum } from "ldrs";
quantum.register();

const renderErrorMessages = (error) => {
  if (typeof error === "object" && error !== null) {
    return Object.keys(error).map((key, index) => (
      <div key={index} className="text-warning">
        {error[key]}
      </div>
    ));
  }
  return <div className="text-warning">{error}</div>;
};

const FormPage = ({
  formConfig,
  stepConfig,
  setFieldValue,
  handleChange,
  handleSelect,
  errors,
  touched,
  handleBlur,
  values,
  validateField,
  setFieldTouched,
  myFormData,
  setMyFormData,
  isSubmissionStep,
  isLastStep,
  error,
  success,
  submissionStatus,
  tasksStatus,
}) => {
  if (isSubmissionStep && submissionStatus.tasks) {
    return (
      <div className="pb-4">
        <h3 className="text-dark display-6">{stepConfig.title}</h3>
        {submissionStatus.tasks.map((task, index) => (
          <div key={index} className="fs-4 m-3">
            {task.status === "loading" && (
              <l-quantum size="15" color="#3F80EA" fixedWidth></l-quantum>
            )}
            {task.status === "succeeded" && (
              <FontAwesomeIcon
                icon={faCheck}
                className="text-success"
                fixedWidth
              />
            )}
            {task.status === "failed" && (
              <FontAwesomeIcon
                icon={faExclamation}
                className="text-warning"
                fixedWidth
              />
            )}
            <strong className="ms-3">{task.name}</strong>
            {task.status === "succeeded" && (
              <div className="text-success fs-6 ms-5">
                {task.message}
                <br />
                <a href="#">Click to view</a>
              </div>
            )}
            {task.status === "failed" && (
              <div className="text-warning fs-6 ms-5">
                {task.message}
                <br />
                <a href="#">Start troubleshooting</a>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <div className="pb-4">
        <h3 className="text-dark display-6">{stepConfig.title}</h3>
      </div>
      <div className="fv-row mb-6">
        {stepConfig.fields.map((field, index) => {
          const shouldDisplay = field.condition
            ? field.condition(values)
            : true;

          if (!shouldDisplay) {
            return null;
          }

          if (field.type === "select") {
            return (
              <div key={index} className="mb-4">
                <Form.Group className={field.condition ? "ms-3" : ""}>
                  <Form.Label
                    className={field.condition ? "fs-5" : "fs-4 text-dark"}
                  >
                    {field.condition ? (
                      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                    ) : (
                      ""
                    )}
                    {field.title || field.label}
                  </Form.Label>
                  <Select
                    name={field.name}
                    options={field.options}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={(option) => {
                      setFieldValue(field.name, option.value);
                      setFieldTouched(field.name, true, false);
                      validateField(field.name)
                        .then(() => {
                          setMyFormData({
                            ...myFormData,
                            [field.name]: {
                              value: option.value,
                              label: option.label,
                            },
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                    value={field.options.find(
                      (option) =>
                        option.value === values[field.name]?.value ?? ""
                    )}
                    onBlur={handleBlur}
                    // isClearable={true}
                  />
                  {errors[field.name] && touched[field.name] && (
                    <div className="mt-1">
                      {renderErrorMessages(errors[field.name])}
                    </div>
                  )}
                </Form.Group>
              </div>
            );
          }
          if (field.type === "checkbox") {
            return (
              <div key={index} className="mb-4">
                <Form.Group className={field.condition ? "ms-3" : ""}>
                  <Form.Label
                    className={field.condition ? "fs-5" : "fs-4 text-dark"}
                  >
                    {field.condition ? (
                      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                    ) : (
                      ""
                    )}
                    {field.title || field.label}
                  </Form.Label>
                  {field.infoBox ? (
                    <Alert variant="primary" className="alert-outline">
                      <div className="alert-icon">
                        <FontAwesomeIcon icon={faQuestion} fixedWidth />
                      </div>
                      <div className="alert-message">{field.infoBox}</div>
                    </Alert>
                  ) : (
                    ""
                  )}
                  <Form.Check
                    type="checkbox"
                    name={field.name}
                    id={field.name}
                    label={field.label}
                    checked={values[field.name]}
                    onChange={(event) => {
                      const { checked } = event.target;
                      setFieldValue(field.name, checked); // Directly use the checked value
                      setFieldTouched(field.name, true, false);

                      validateField(field.name)
                        .then(() => {
                          // If validation passes, update your form data state
                          setMyFormData((prevFormData) => ({
                            ...prevFormData,
                            [field.name]: checked,
                          }));
                        })
                        .catch((error) => {
                          // Handle the validation error if needed
                          console.log(error);
                        });
                    }}
                    onBlur={handleBlur}
                  />
                  {errors[field.name] && touched[field.name] && (
                    <div className="text-warning mt-1">
                      {errors[field.name]}
                    </div>
                  )}
                </Form.Group>
              </div>
            );
          }
          if (field.type === "switch") {
            return (
              <div key={index} className="mb-4">
                <Form.Group className={field.condition ? "ms-3" : ""}>
                  <Form.Label
                    className={field.condition ? "fs-5" : "fs-4 text-dark"}
                  >
                    {field.condition ? (
                      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                    ) : (
                      ""
                    )}
                    {field.title || field.label}
                  </Form.Label>
                  {field.infoBox ? (
                    <Alert variant="primary" className="alert-outline">
                      <div className="alert-icon d-flex align-items-center">
                        <FontAwesomeIcon icon={faQuestion} fixedWidth />
                      </div>
                      <div className="alert-message">{field.infoBox}</div>
                    </Alert>
                  ) : (
                    ""
                  )}
                  <Form.Check
                    type="switch"
                    name={field.name}
                    label={field.label}
                    id={field.name}
                    checked={values[field.name]}
                    onChange={(event) => {
                      const { checked } = event.target;
                      setFieldValue(field.name, checked); // Directly use the checked value
                      setFieldTouched(field.name, true, false);

                      validateField(field.name)
                        .then(() => {
                          // If validation passes, update your form data state
                          setMyFormData((prevFormData) => ({
                            ...prevFormData,
                            [field.name]: checked,
                          }));
                        })
                        .catch((error) => {
                          // Handle the validation error if needed
                          console.log(error);
                        });
                    }}
                    onBlur={handleBlur}
                  />
                  {errors[field.name] && touched[field.name] && (
                    <div className="text-warning mt-1">
                      {errors[field.name]}
                    </div>
                  )}
                </Form.Group>
              </div>
            );
          }
          if (field.type === "date") {
            return (
              <div key={index} className="mb-4">
                <Form.Group className={field.condition ? "ms-3" : ""}>
                  <Form.Label
                    className={field.condition ? "fs-5" : "fs-4 text-dark"}
                  >
                    {field.condition ? (
                      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                    ) : (
                      ""
                    )}
                    {field.title || field.label}
                  </Form.Label>
                  <div className=" d-flex justify-content-center">
                    <DatePicker
                      selectedDate={values[field.name]}
                      setSelectedDate={(date) => {
                        setFieldValue(field.name, date);
                        setFieldTouched(field.name, true, false);
                        validateField(field.name)
                          .then(() => {
                            setMyFormData({
                              ...myFormData,
                              [field.name]: date,
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                    />
                  </div>
                  {errors[field.name] && touched[field.name] && (
                    <div className="text-warning mt-1">
                      {errors[field.name]}
                    </div>
                  )}
                </Form.Group>
              </div>
            );
          }
          if (field.type === "range") {
            return (
              <div key={index} className="mb-4">
                <Form.Group className={field.condition ? "ms-3" : ""}>
                  <Form.Label
                    className={field.condition ? "fs-5" : "fs-4 text-dark"}
                  >
                    {field.condition ? (
                      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                    ) : (
                      ""
                    )}
                    {field.title || field.label}
                  </Form.Label>
                  <div
                    className="my-3 pb-1"
                    style={{ width: "90%", margin: "0px auto" }}
                  >
                    <Slider
                      defaultValue={values[field.name]}
                      step={50}
                      graduated
                      progress
                      min={100}
                      max={1000}
                      renderMark={(mark) => {
                        if ([250, 500, 750, 1000].includes(mark)) {
                          return <span>{mark} Mbps</span>;
                        }
                        return null;
                      }}
                      onChange={(value) => {
                        setFieldValue(field.name, value);
                        setFieldTouched(field.name, true, false);
                        validateField(field.name)
                          .then(() => {
                            setMyFormData({
                              ...myFormData,
                              [field.name]: value,
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                    />
                  </div>
                  {errors[field.name] && touched[field.name] && (
                    <div className="text-warning mt-1">
                      {errors[field.name]}
                    </div>
                  )}
                </Form.Group>
              </div>
            );
          }
          if (field.type === "confirm") {
            return (
              <div key={index} className="mb-4">
                {formConfig.steps.map((step, stepIndex) => {
                  if (step.confirmationStep) {
                    return null;
                  }
                  return (
                    <div key={stepIndex}>
                      <h4>{step.title}</h4>
                      {step.fields.map((field, fieldIndex) => {
                        if (
                          myFormData.hasOwnProperty(field.name) &&
                          myFormData[field.name] !== null
                        ) {
                          let value = myFormData[field.name];
                          if (field.type === "date") {
                            value = format(value, "do LLLL yyyy");
                          }
                          if (
                            field.type === "switch" ||
                            field.type === "checkbox"
                          ) {
                            value = value ? "Yes" : "No";
                          }
                          if (field.type === "select") {
                            value = value.label;
                          }
                          return (
                            <div key={fieldIndex}>
                              <h5 className="my-3 ms-3">
                                <span className="text-muted">
                                  {field.confirmLabel}:
                                </span>{" "}
                                {value}
                              </h5>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
              </div>
            );
          }
          // Add other field types (text, date, etc.) as needed
          return null;
        })}
      </div>
    </>
  );
};

export default FormPage;
