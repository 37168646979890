import {
  faHouse,
  faLocationDot,
  faUserGroup,
  // faHeadset,
  // faSterlingSign,
  faChartLine,
  // faWifi,
  // faServer,
  // faShieldHalved,
  // faBagShopping,
  //faBell,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

const dashboardsSection = [
  {
    href: "/dashboard/default",
    icon: faHouse,
    title: "Overview",
    permissionName: "overview:default",
  },
  /*{
    href: "/dashboard/tenant",
    icon: faHouse,
    title: "Tenant Overview",
    permissionName: "overview:tenant",
  },*/
];

const manageSection = [
  {
    href: "/buildings/portfolio",
    icon: faLocationDot,
    title: "Building Portfolio",
    permissionName: "buildings",
    disabledForLandlordIds: [42],
  },
  {
    href: "/customers/customers",
    icon: faUserGroup,
    title: "Customers",
    permissionName: "customers",
    disabledForLandlordIds: [42],
  },
  // {
  //   href: "/tickets/list",
  //   icon: faHeadset,
  //   title: "Support Tickets",
  //   permissionName: "tickets",
  //   disabledForLandlordIds: [42],
  // },
  // {
  //   href: "/billing/list",
  //   icon: faSterlingSign,
  //   title: "Billing",
  //   permissionName: "billing",
  //   disabledForLandlordIds: [42],
  // },
  {
    href: "/monitoring/selectbuilding",
    icon: faChartLine,
    title: "Network Monitoring",
    permissionName: "monitoring",
    disabledForLandlordIds: [42],
  },
  // {
  //   href: "/networks/tenant",
  //   icon: faServer,
  //   title: "Tenant Networks",
  //   permissionName: "tenantnetworks",
  //   disabledForLandlordIds: [42],
  // },
  // {
  //   href: "/wifi/list",
  //   icon: faWifi,
  //   title: "Wireless Networks",
  //   permissionName: "wifi",
  //   disabledForLandlordIds: [42],
  // },
  // {
  //   href: "/credentials/dot11",
  //   icon: faShieldHalved,
  //   title: "Wi-Fi Credentials",
  //   permissionName: "credentials",
  // },
  // {
  //   href: "/signups/list",
  //   icon: faBagShopping,
  //   title: "Customer Signup Forms",
  //   permissionName: "bundles",
  //   disabledForLandlordIds: [42],
  // },
];

// const tenantManageSection = [
//   {
//     href: "/customers/orgProfile/",
//     icon: faUserGroup,
//     title: "Your Account",
//     permissionName: "customers:org",
//     disabledForLandlordIds: [42],
//   },
//   {
//     href: "/tickets/list",
//     icon: faHeadset,
//     title: "Support Tickets",
//     permissionName: "tickets",
//     disabledForLandlordIds: [42],
//   },
//   {
//     href: "/billing/list",
//     icon: faSterlingSign,
//     title: "Billing",
//     permissionName: "billing",
//     disabledForLandlordIds: [42],
//   },
//   {
//     href: "/credentials/dot11",
//     icon: faShieldHalved,
//     title: "Wi-Fi Credentials",
//     permissionName: "credentials",
//     disabledForLandlordIds: [42],
//   },
// ];

/*const configureSection = [
  {
    href: "/notifications",
    icon: faBell,
    title: "Notifications",
    permissionName: "notifications:own",
  },
];*/

const adminSection = [
  {
    href: "/system/settings",
    icon: faGear,
    title: "System Settings",
    permissionName: "systemsettings",
    disabledForLandlordIds: [42],
  },
];

const navItems = [
  {
    title: "Dashboards",
    pages: dashboardsSection,
    permissionName: "dashboards",
    disabledForLandlordIds: [42],
  },
  {
    title: "Manage",
    pages: manageSection,
    permissionName: "manage",
  },
  // {
  //   title: "Account Management",
  //   pages: tenantManageSection,
  //   permissionName: "tenant",
  //   disabledForLandlordIds: [42],
  // },
  /*{
    title: "Configure",
    pages: configureSection,
    permissionName: "configure",
  },*/
  {
    title: "Administration",
    pages: adminSection,
    permissionName: "admin",
    disabledForLandlordIds: [42],
  },
];

export default navItems;
