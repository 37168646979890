import React, { useState, useContext } from "react";
import { Tab, Modal, Button, Card, Form } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { apiConfig } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { ReactComponent as LogoLoader } from "../../../assets/img/layer8-animated-loader.svg";
import LogoLoader from "../../../components/LogoLoader";
import moment from "moment";

import {
  faPlus,
  faKey,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../hooks/useAuth";
import NotyfContext from "../../../contexts/NotyfContext";
import IsAllowed from "../../../components/IsAllowed";
import NotAuthorised from "../../../components/NotAuthorised";
import NotFound from "../../../components/NotFound";
import axios from "axios";
import { useSelector } from "react-redux";
import ColumnFilteringTable from "../../../components/tables/ColumnFilteringTable";

const UserManagement = ({
  openModals,
  toggle,
  passedData,
  dispatch,
  setRefreshData,
  passData,
  refreshData,
}) => {
  console.log("passedData", passedData);
  const { userAccessToken, user } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  //const selectedCustomer = useSelector((state) => state.searchfilters);
  const usersTableColumns = [
    // {
    //   Header: "Account Number",
    //   accessor: "accountNumber",
    //   Cell: (e) => (
    //     <div>
    //       <Link to={e.value ? `/customers/profile/${e.value}` : "#"}>
    //         <Button variant={e.value ? "outline-primary" : "outline-secondary"}>
    //           {e.value ? e.value : "----------"}
    //         </Button>
    //       </Link>
    //     </div>
    //   ),
    // },
    {
      Header: "Account Number",
      accessor: "accountNumber",
      Cell: (e) => (
        <div>
          {Array.isArray(e.value) ? (
            e.value.map((account, index) => (
              <div key={index} style={{ marginBottom: "5px" }}>
                <Link to={account ? `/customers/profile/${account}` : "#"}>
                  <Button
                    variant={account ? "outline-primary" : "outline-secondary"}
                  >
                    {account ? account : "----------"}
                  </Button>
                </Link>
              </div>
            ))
          ) : (
            <Link to={e.value ? `/customers/profile/${e.value}` : "#"}>
              <Button
                variant={e.value ? "outline-primary" : "outline-secondary"}
              >
                {e.value ? e.value : "----------"}
              </Button>
            </Link>
          )}
        </div>
      ),
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Last Login",
      accessor: "last_login",
      Cell: (e) =>
        e.value ? moment(e.value).local().format("DD-MM-YYYY HH:mm") : "Never",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <IsAllowed to="edit:systemsettings:users">
            <FontAwesomeIcon
              icon={faKey}
              className="ms-2"
              onClick={() => {
                toggle("sendPasswordReset");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
            <FontAwesomeIcon
              icon={faEdit}
              className="ms-2"
              onClick={() => {
                toggle("modifyGatewayUser");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
          <IsAllowed to="edit:systemsettings:users">
            <FontAwesomeIcon
              icon={faTrash}
              className="ms-2"
              onClick={() => {
                toggle("deleteUser");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
        </>
      ),
    },
  ];
  // Form States for Add New User Form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(0);
  const [operator, setOperator] = useState(selectedLandlord.landlordid);
  const [operatorDisabled, setOperatorDisabled] = useState(0);
  const [excellId, setExcellId] = useState("");
  // Load users list - modify to only load users for selectedLandlord / selectedAccount
  // if role not super or wavenet
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let query = "";
    if (excellId && excellId.length > 0) {
      query = `?account_number=${excellId}`;
    }
    console.log(query);
    axios
      .get(apiConfig.usersApiUrl + "users" + query, axiosConfig)
      .then((response) => {
        let usersList = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach((user) => {
            let role =
              user.app_metadata &&
              user.app_metadata.roles &&
              user.app_metadata.roles.length > 0
                ? user.app_metadata?.roles[0]
                : "";
            let accountNumber =
              user.app_metadata &&
              user.app_metadata.accountnumber &&
              user.app_metadata.accountnumber.length > 0
                ? user.app_metadata?.accountnumber
                : [];
            let landlordId =
              user.app_metadata &&
              user.app_metadata.landlordid &&
              user.app_metadata.landlordid.length > 0
                ? user.app_metadata?.landlordid[0]
                : "";
            usersList.push({
              username: user.email,
              name: user.name,
              avatarUrl: user.picture,
              role: role,
              accountNumber: accountNumber,
              landlordId: landlordId,
              emailVerified: user.email_verified,
              last_login: user.last_login || null,
            });
          });
        }
        console.log("Users List", usersList);
        setUsersList(usersList);
        setLoading(false);
        setNoData(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [refreshData, bearerToken, selectedLandlord, user, excellId]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  React.useEffect(() => {
    // Modify to only return own Operator if role not super or wavenet
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "permissions", axiosConfig)
      .then((response) => {
        if (response.data[user.roles].includes("view:operators:all")) {
          axios
            .get(
              apiConfig.connectedApiUrl + "operators?page_size=1000",
              axiosConfig
            )
            .then(function (response) {
              let operatorsList = [];
              response.data.operators.items.forEach((operator) => {
                operatorsList.push({
                  value: operator.id,
                  label:
                    operator.operator_name +
                    " (" +
                    operator.operator_code +
                    ")",
                });
              });
              //setLoading(false);
              setOperatorOptions(operatorsList);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
        } else {
          const operatorsList = [
            {
              value: selectedLandlord.landlordid,
              label: selectedLandlord.landlordname,
            },
          ];
          setOperatorOptions(operatorsList);
          setOperatorDisabled(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bearerToken, selectedLandlord, user]);
  // Customer Options for create new user modal dropdown
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isLoadingCustomerOptions, setIsLoadingCustomerOptions] =
    useState(true);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    setIsLoadingCustomerOptions(true);
    setExcellId(null);
    if (operator) {
      axios
        .get(
          apiConfig.symbillApiUrl + "newsymbillapi/byCtidList/" + operator,
          axiosConfig
        )
        .then(function (response) {
          let customersList = [];
          response.data.forEach((customer) => {
            customersList.push({
              value: customer.accountNumber,
              label: customer.companyName + " (" + customer.accountNumber + ")",
            });
          });
          //setLoading(false);
          setCustomerOptions(customersList);
          setIsLoadingCustomerOptions(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setIsLoadingCustomerOptions(false);
        });
    } else {
      setCustomerOptions([]);
      setIsLoadingCustomerOptions(false);
    }
  }, [operator, bearerToken]);
  // Role Options for create new user modal dropdown
  const [roleOptions, setRoleOptions] = useState([]);
  React.useEffect(() => {
    // Modify to only return roles below user's role
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "permissions/hierarchy", axiosConfig)
      .then(function (response) {
        let rolesList = [];
        const currentUserRoleRank = response.data
          .filter((role) => role.role_name === user.roles)
          .shift().rank;
        response.data.forEach((role) => {
          if (role.rank >= currentUserRoleRank) {
            rolesList.push({
              value: role.role_name,
              label: role.role_name,
            });
          }
        });
        //setLoading(false);
        setRoleOptions(rolesList);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [bearerToken, user]);

  const notyf = useContext(NotyfContext);

  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  // excellId field for form submission
  const [submissionExcellIds, setSubmissionExcellIds] = useState([]);

  // Handle create Gateway user form submission
  const handleCreateUserSubmit = (event) => {
    event.preventDefault();
    const excellIdsArray = submissionExcellIds.map((item) => item.value);
    const createUserBody = {
      email: email,
      name: name,
      landlordid: operator,
      role: role || passedData.role,
      accountnumber:
        excellIdsArray.length > 0 ? excellIdsArray : passedData.accountNumber,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .post(apiConfig.usersApiUrl + "users", createUserBody, axiosConfig)
      .then(function (response) {
        if (response.status === 201) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
    setName("");
    setEmail("");
  };

  // Handle update Gateway user form submission

  const handleModifyUserSubmit = (event) => {
    event.preventDefault();
    const excellIdsArray = submissionExcellIds.map((item) => item.value);
    const modifyUserBody = {
      email: passedData.username,
      name: passedData.name,
      accountnumber:
        excellIdsArray.length > 0 ? excellIdsArray : passedData.accountNumber,
      role: role || passedData.role,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(`${apiConfig.usersApiUrl}users`, modifyUserBody, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      });
    setName("");
    setEmail("");
  };

  const sendPasswordReset = (data) => {
    // Use axios to send pwreset email
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let encodedUsername = encodeURIComponent(data.username);
    axios
      .get(
        `${apiConfig.usersApiUrl}users/${encodedUsername}/pwreset`,
        axiosConfig
      )
      .then(function (response) {
        if (response.status === 200) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(error);
      });
  };

  const deleteUser = (data) => {
    // Use axios to delete the credential
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let encodedUsername = encodeURIComponent(data.username);
    axios
      .delete(`${apiConfig.usersApiUrl}users/${encodedUsername}`, axiosConfig)
      .then(function (response) {
        if (response.status === 200) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch(function (error) {
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(error);
      });
    // Launch notyf based on the API response
  };

  return (
    <>
      <Tab.Pane eventKey="#1">
        <IsAllowed to="view:systemsettings:users" fallback={<NotAuthorised />}>
          <Card.Header>
            <Card.Title className="mb-0">User Management</Card.Title>
          </Card.Header>
          <Card.Body className="py-0">
            <IsAllowed to="edit:systemsettings:users">
              <Button
                variant="primary"
                className="mt-n4 mb-3 float-end"
                onClick={() => toggle("addNewUser")}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Platform User
              </Button>
              <div className="clearfix"></div>
            </IsAllowed>
            <p>
              Use the table below to manage Platform users and their access
              credentials. If you cannot find the credentials you are looking
              for, please select a specific building operator and customer
              account number.
            </p>
            <div className="row">
              <Form.Group className="mb-3 col-md-5">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={operatorOptions}
                  onChange={(e) => {
                    setOperator(e.value);
                    setExcellId(null);
                  }}
                  isDisabled={operatorDisabled}
                  defaultValue={operatorOptions
                    .filter(
                      (option) => option.value === selectedLandlord.landlordid
                    )
                    .shift()}
                  value={
                    operator
                      ? operatorOptions.find(
                          (option) => option.value === operator
                        )
                      : null
                  }
                />
                {operatorDisabled === 1 ? (
                  <p className="text-sm pt-2">
                    Showing users assigned to the{" "}
                    <b>{selectedLandlord.landlordname}</b> tenant.
                  </p>
                ) : (
                  <p className="text-sm pt-2">1. Select a building operator</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3 col-md-5">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={
                    isLoadingCustomerOptions
                      ? [{ value: "", label: "Loading..." }]
                      : customerOptions
                  }
                  isDisabled={
                    isLoadingCustomerOptions || customerOptions.length === 0
                  }
                  onChange={(e) => setExcellId(e.value)}
                  value={
                    excellId
                      ? customerOptions.find(
                          (option) => option.value === excellId
                        )
                      : null
                  }
                />
                <p className="text-sm pt-2">
                  2. Filter by customer account number
                </p>
              </Form.Group>
              <Form.Group className="mb-3 col-md-2">
                <Button
                  className="btn btn-light btn-lg"
                  onClick={() => {
                    setOperator(null);
                    setExcellId(null);
                  }}
                >
                  Clear Filters
                </Button>
              </Form.Group>
            </div>
            {loading && (
              <>
                <LogoLoader className="d-block m-auto p-4" />
              </>
            )}
            {!loading && noData && <NotFound />}
            {!loading && !noData && (
              <>
                <ColumnFilteringTable
                  columns={usersTableColumns}
                  data={usersList}
                  actions
                />
                <div className="text-sm text-muted text-center mb-2 mt-n1">
                  Displaying 1,000 users maximum. Use filters to narrow down
                  your search.
                </div>
              </>
            )}
          </Card.Body>
        </IsAllowed>
      </Tab.Pane>
      <Modal
        show={openModals["addNewUser"]}
        onHide={() => toggle("addNewUser")}
        centered
      >
        <Form onSubmit={handleCreateUserSubmit}>
          <Modal.Header closeButton>
            <b>Admin Function:</b>&nbsp; Add New Platform User
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              <>
                <Form.Group className="mb-3">
                  <Form.Label>1. User's Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>2. Email Address (Username)</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter a valid email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="text-sm pt-2">
                    The email address must be valid - the user will need to
                    verify and set their password by clicking a link.
                  </p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>3. Building Operator</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={operatorOptions}
                    onChange={(e) => setOperator(e.value)}
                    isDisabled={operatorDisabled}
                    defaultValue={operatorOptions
                      .filter(
                        (option) => option.value === selectedLandlord.landlordid
                      )
                      .shift()}
                  />
                  {operatorDisabled === 1 ? (
                    <p className="text-sm pt-2">
                      This user will be assigned to the{" "}
                      <b>{selectedLandlord.landlordname}</b> tenant.
                    </p>
                  ) : (
                    <p className="text-sm pt-2">
                      Select the building operator this user should be assigned
                      to.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>4. Assign To Customer</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={customerOptions}
                    onChange={(e) => {
                      setSubmissionExcellIds(e);
                    }}
                    isMulti
                  />
                  <p className="text-sm pt-2">
                    Select the customer or organisation this user should be
                    assigned to.
                  </p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>5. Role</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={roleOptions}
                    onChange={(e) => setRole(e.value)}
                  />
                  <p className="text-sm pt-2">
                    This sets the permission level for the user you are about to
                    create.
                  </p>
                </Form.Group>
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggle("addNewUser")}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="info"
              onClick={() => {
                toggle("addNewUser");
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
              }}
            >
              Create User
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={openModals["modifyGatewayUser"]}
        onHide={() => toggle("modifyGatewayUser")}
        centered
      >
        <Form onSubmit={handleModifyUserSubmit}>
          <Modal.Header closeButton>
            <b>Admin Function:</b>&nbsp; Update Platform User Role
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              <>
                <Form.Group className="mb-3">
                  <Form.Label>User's Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    disabled
                    value={passedData.name}
                    //onLoad={setName(passedData.name)}
                    on
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address (Username)</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter a valid email address"
                    disabled
                    value={passedData.username}
                    //onLoad={setEmail(passedData.username)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Account Number(s)</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={customerOptions}
                    onChange={(e) => setSubmissionExcellIds(e)}
                    defaultValue={
                      passedData && Array.isArray(passedData.accountNumber)
                        ? passedData.accountNumber.map((account) => {
                            const option = customerOptions.find(
                              (opt) => opt.value === account
                            );
                            return option
                              ? option
                              : { label: account, value: account };
                          })
                        : []
                    }
                    isMulti
                  />
                  <p className="text-sm pt-2">
                    This updates the account numbers this user can view in the
                    Platform.
                  </p>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={roleOptions}
                    onChange={(e) => setRole(e.value)}
                    defaultValue={
                      passedData &&
                      typeof passedData.role !== "undefined" &&
                      passedData.role !== null
                        ? (() => {
                            const option = roleOptions.find(
                              (opt) => opt.label === passedData.role
                            );
                            return option
                              ? option
                              : {
                                  label: passedData.role,
                                  value: passedData.role,
                                };
                          })()
                        : null
                    }
                  />
                  <p className="text-sm pt-2">
                    This updates the permission level for the user.
                  </p>
                </Form.Group>
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggle("modifyGatewayUser")}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="info"
              onClick={() => {
                toggle("modifyGatewayUser");
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
              }}
            >
              Update User
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={openModals["sendPasswordReset"]}
        onHide={() => toggle("sendPasswordReset")}
        centered
      >
        <Modal.Header closeButton>
          <b>Admin Function:</b>&nbsp; Send Password Reset
        </Modal.Header>
        <Modal.Body className="m-3">
          <p className="text-left my-0">
            You can send a password reset email to <b>{passedData.username}</b>{" "}
            by clicking the button below. The user will need to follow the link
            in the email to reset their password.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => toggle("sendPasswordReset")}
          >
            Close
          </Button>
          <Button
            variant="info"
            onClick={() => {
              toggle("sendPasswordReset");
              sendPasswordReset(passedData);
            }}
          >
            Send Password Reset
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openModals["deleteUser"]}
        onHide={() => toggle("deleteUser")}
        centered
      >
        <Modal.Header closeButton>
          <b>Admin Function:</b>&nbsp; Delete Credential
        </Modal.Header>
        <Modal.Body className="m-3">
          <p className="text-left my-0">
            This will permanently delete the
            <b> {passedData.username}</b> user from the Platform. If you wish to
            proceeed please confirm by clicking below:
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => toggle("deleteUser")}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              toggle("deleteUser");
              deleteUser(passedData);
              setTimeout(() => {
                dispatch(setRefreshData());
              }, 2000);
            }}
          >
            Delete User Credential
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserManagement;
