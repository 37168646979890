import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBuildingsRequest } from "../../../redux/slices/buildingsSlice";
import BuildingListItem from "./BuildingsListItem";
import NotFound from "../../../components/NotFound";
// import LogoLoader from "../../../components/LogoLoader";
import LongDataLoader from "../../../components/LongDataLoader";
import useAuth from "../../../hooks/useAuth";

import { Col } from "react-bootstrap";

const BuildingList = () => {
  const { userAccessToken, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const { buildingsData, loading, noData, error } = useSelector(
    (state) => state.buildings
  );
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  useEffect(() => {
    if (isAuthenticated && selectedLandlord.landlordid) {
      const bearerToken = `Bearer ${userAccessToken}`;
      dispatch(
        fetchBuildingsRequest({ bearerToken, selectedLandlord, query: true })
      );
    }
  }, [dispatch, userAccessToken, selectedLandlord, isAuthenticated]);

  return (
    <>
      {loading && <LongDataLoader />}
      {noData && <NotFound />}
      {error && <NotFound />}
      {buildingsData.map((building) => (
        <Col md="6" lg="3" key={building.id}>
          <BuildingListItem
            name={building.building_name}
            status={building.status}
            color={
              building.status === "Live Building" ? "success" : "secondary"
            }
            percentage={
              Math.round(
                (building?.live_tenancies_count / building?.rooms_count) * 100
              ) || 0
            }
            description={building.description}
            image=""
            buildingid={building.id}
            numrooms={building?.rooms_count || 0}
            numcustomers={building?.live_tenancies_count || 0}
            technicalreference={building.wifi_id}
          />
        </Col>
      ))}
    </>
  );
};

export default BuildingList;
