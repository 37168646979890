import React from "react";
import { useSelector } from "react-redux";

const IsDisabledForLandlordId = ({ children, disabledForLandlordIds = [] }) => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  if (
    selectedLandlord &&
    !disabledForLandlordIds.includes(selectedLandlord.landlordid)
  ) {
    return <>{children}</>;
  }
  return null;
};

export default IsDisabledForLandlordId;
